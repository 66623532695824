import React, { FC } from "react";
import { InputOption } from "@lookiero/aurora";

interface CheckboxProps {
  readonly checked?: boolean;
  readonly value?: string;
  readonly onChange?: () => void;
}
const Checkbox: FC<CheckboxProps> = ({ checked = false, value = "", onChange }) => (
  <InputOption checked={checked} name="product-variant-chosen" testID="checkbox" value={value} onChange={onChange} />
);

export { Checkbox };
