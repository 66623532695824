import React, { FC } from "react";
import { Description } from "../../components/atoms/description/Description";
import { Title } from "../../components/atoms/title/Title";
import { Body } from "../../components/layouts/body/Body";
import { Layout } from "../../components/layouts/layout/Layout";

const NotFoundStatic: FC = () => (
  <Layout>
    <Body>
      <Title>Unable to find that page</Title>
      <Description>We&lsquo;re sorry, we couldn&lsquo;t find that page</Description>
    </Body>
  </Layout>
);

export { NotFoundStatic };
