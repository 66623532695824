import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space05, space1, space2, space3 } = theme();

const style = StyleSheet.create({
  notification: {
    backgroundColor: colorBgBase,
    display: "flex",
    flexDirection: "row",
    gap: space2,
    justifyContent: "center",
    padding: space3,
  },
  text: {
    paddingTop: space1 + space05,
  },
});

export { style };
