import { useFonts } from "expo-font";
import React, { FC } from "react";
import { Platform } from "react-native";
import { Route, Routes } from "react-router-native";
import { Aurora, Spinner } from "@lookiero/aurora";
import { EndpointFunction } from "@lookiero/i18n";
import { i18n } from "@lookiero/i18n-react";
import { fetchHttpGet } from "@lookiero/sty-psp-http";
import { fetchTranslations, translationExternalEndpoint } from "@lookiero/sty-psp-i18n";
import { SentryEnvironment } from "@lookiero/sty-psp-logging";
import { bootstrap as previewBootstrap } from "./infrastructure/delivery/bootstrap";
import { httpEnvironmentFetcher } from "./infrastructure/projection/environment/model/httpEnvironmentFetcher";
import { EnvironmentProvider } from "./infrastructure/projection/environment/react/useEnvironment";
import { AsyncGtmTracker } from "./infrastructure/tracking/AsyncGtmTracker";
import { TrackerProvider } from "./infrastructure/tracking/useTracker";
import { root } from "./infrastructure/ui/Root";
import { FeatureToggleProvider } from "./infrastructure/ui/featureToggle/useFeatureToggle";
import { Router } from "./infrastructure/ui/routing/router/Router";
import { VERSION } from "./version";

type OS = typeof Platform.OS;
type Device = Exclude<OS, "macos" | "windows">;
const device = Platform.OS as Device;

interface BootstrapFunction {
  (): Promise<FC>;
}

const bootstrap: BootstrapFunction = async () => {
  const environment = await httpEnvironmentFetcher({
    httpGet: fetchHttpGet({ apiUrl: () => "", device, version: VERSION }),
  });

  if (!environment) {
    // eslint-disable-next-line react/display-name
    return () => <Spinner />;
  }

  const tracker = await AsyncGtmTracker.init({
    project: environment.tracking.project,
    gtmId: environment.tracking.gtmId,
  });

  const sentryConfig: SentryEnvironment = {
    publicKey: environment.logging.sentryPublicKey,
    release: VERSION,
    project: environment.logging.sentryProject,
    environment: `${Platform.OS}-${__DEV__ ? "DEV" : "PROD"}`,
  };

  const translations: EndpointFunction[] = [
    (locale) =>
      translationExternalEndpoint({
        translationsUrl: environment.internationalization.externalEndpoint,
        projects: [["inventory-catalog"], ["box-preview"]],
      })(locale),
  ];

  const I18n = i18n({
    fetchTranslation: fetchTranslations({ translations }),
    contextId: "BoxPreviewI18n",
  });

  const { Component: Messaging } = previewBootstrap({
    apiUrl: () => environment.labsBackUrl,
  });

  const Root = root({ Messaging, I18n, sentry: () => sentryConfig })({ customerId: undefined });

  const ExpoRoot = () => {
    const [fontsLoaded] = useFonts({
      ["AreaInktrap-Semibold"]: require("@lookiero/aurora-fonts/AreaInktrap-Semibold.otf"),
      ["AreaNormal-Semibold"]: require("@lookiero/aurora-fonts/AreaNormal-Semibold.otf"),
      ["AreaNormal-Extrabold"]: require("@lookiero/aurora-fonts/AreaNormal-Extrabold.otf"),
      auroraicons: require("@lookiero/aurora-iconfont/dist/auroraicons.ttf"),
    });

    // Set scroll restoration as manual for disabling default browser behaviour
    history.scrollRestoration = "manual";

    return fontsLoaded ? (
      <EnvironmentProvider environment={environment}>
        <FeatureToggleProvider featureToggles={environment.featureToggles}>
          <TrackerProvider tracker={tracker}>
            <Aurora usePortal={false} useStack={false}>
              <Router>
                <Routes>
                  <Route element={<Root />} path="/*" />
                </Routes>
              </Router>
            </Aurora>
          </TrackerProvider>
        </FeatureToggleProvider>
      </EnvironmentProvider>
    ) : (
      <Spinner />
    );
  };

  return ExpoRoot;
};

export { bootstrap };
