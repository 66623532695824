import {
  BoxPreviewProjection,
  BoxPreviewProductVariantProjection,
  ReplaceableByProjection,
} from "../../boxPreview/boxPreview";
import { BoxPreviewFeedbackProjection } from "../../boxPreviewFeedback/boxPreviewFeedback";

interface BoxPreviewWithFeedbackProductVariantProjection extends BoxPreviewProductVariantProjection {
  readonly replacedFor?: ReplaceableByProjection;
}

interface BoxPreviewWithFeedbackProjection extends BoxPreviewProjection {
  readonly replacedFor: Record<string, string>;
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[];
}

interface FromBoxPreviewAndBoxPreviewFeedbackFunctionArgs {
  readonly boxPreview: BoxPreviewProjection;
  readonly boxPreviewFeedback: BoxPreviewFeedbackProjection | undefined;
}

interface FromBoxPreviewAndBoxPreviewFeedbackFunction {
  (args: FromBoxPreviewAndBoxPreviewFeedbackFunctionArgs): BoxPreviewWithFeedbackProjection;
}

const fromBoxPreviewAndBoxPreviewFeedback: FromBoxPreviewAndBoxPreviewFeedbackFunction = ({
  boxPreview,
  boxPreviewFeedback,
}) =>
  boxPreviewFeedback
    ? {
        ...boxPreview,
        productVariants: boxPreview.productVariants.map((boxPreviewProductVariant) => ({
          ...boxPreviewProductVariant,
          chosen: boxPreviewFeedback.chosenProductVariantIds.includes(boxPreviewProductVariant.id),
          replacedFor: boxPreviewProductVariant.replaceableBy.find(
            (productVariant) =>
              productVariant.id === boxPreviewFeedback?.replacedFor[boxPreviewProductVariant?.id as string],
          ),
        })),
        comment: boxPreviewFeedback.comment,
        replacedFor: boxPreviewFeedback.replacedFor,
      }
    : boxPreview;

export type { BoxPreviewWithFeedbackProjection, BoxPreviewWithFeedbackProductVariantProjection };
export { fromBoxPreviewAndBoxPreviewFeedback };
