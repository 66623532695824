import { Platform, StyleSheet } from "react-native";

const style = StyleSheet.create({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sticky: {
    bottom: 0,
    width: "100%",
    ...Platform.select({
      web: { position: "sticky" },
      ios: { position: "absolute" },
      android: { position: "absolute" },
    }),
    zIndex: 0,
  },
});

export { style };
