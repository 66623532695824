import { ViewStyle } from "react-native";
import { StyleSheet } from "react-native";
import { ColumnSize, theme } from "@lookiero/sty-psp-ui";

const { colorBgPrimary, space3, space8, space10, space12 } = theme();

const BACK_TO_TOP_BUTTON_DIAMETER = space12;
const BACK_TO_TOP_BUTTON_BOTTOM = 20;
const BACK_TO_TOP_BUTTON_MARGIN_BOTTOM = space3;

const stylesForColumSize: Record<ColumnSize, ViewStyle> = {
  ["1/4"]: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    left: `calc(100% - (calc(${100 * (1 / 4)}%)) - ${space8 * 2}px)`,
  },
  ["1/3"]: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    left: `calc(100% - (calc(${100 * (1 / 3)}%)) - ${space8 * 2}px)`,
  },
  ["1/2"]: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    left: `calc(50% - ${space8 * 2}px)`,
  },
  ["2/3"]: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    left: `calc(100% - ${space10 * 2}px)`,
  },
};

const style = StyleSheet.create({
  backToTopButton: {
    alignItems: "center",
    backgroundColor: colorBgPrimary,
    borderRadius: BACK_TO_TOP_BUTTON_DIAMETER / 2,
    height: BACK_TO_TOP_BUTTON_DIAMETER,
    justifyContent: "center",
    width: BACK_TO_TOP_BUTTON_DIAMETER,
  },
  sticky: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    width: "fit-content",
    zIndex: 10,
  },
  ...stylesForColumSize,
});

export { style, BACK_TO_TOP_BUTTON_BOTTOM, BACK_TO_TOP_BUTTON_MARGIN_BOTTOM };
