import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius3, borderWidth2, colorBorderInteractiveSelected, space2, space6 } = theme();

const PRODUCT_VARIANT_HEIGHT = 70;
const PRODUCT_VARIANT_WIDTH = 56;

const style = StyleSheet.create({
  colorPickerLarge: {
    flex: 1,
    flexWrap: "wrap",
  },
  colorPickerSmall: {
    paddingHorizontal: space6,
  },
  contentContainer: {
    gap: space2,
  },
  flatList: {
    flexGrow: 0,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    height: "fit-content",
  },
  // eslint-disable-next-line react-native/no-color-literals
  productVariant: {
    borderColor: "transparent",
    borderRadius: borderRadius3,
    borderWidth: borderWidth2,
    height: PRODUCT_VARIANT_HEIGHT,
    overflow: "hidden",
    width: PRODUCT_VARIANT_WIDTH,
  },
  productVariantActive: {
    borderColor: colorBorderInteractiveSelected,
  },
});

export { style };
