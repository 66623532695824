import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import { Button } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import {
  BoxPreviewProductVariantProjection,
  ReplaceableByProjection,
} from "../../../../../projection/boxPreview/boxPreview";
import { BoxPreviewImageViewTrackerParams } from "../../../../tracking/tracking";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Layout } from "../../layouts/layout/Layout";
import { ColorPicker } from "../../organisms/colorPicker/ColorPicker";
import { ProductVariantGallery } from "../../organisms/productVariantGallery/ProductVariantGallery";
import { ItemDetailHeader } from "../header/itemDetailHeader/ItemDetailHeader";
import { style } from "./ItemDetail.style";

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface ItemDetailProps {
  readonly productVariant: BoxPreviewProductVariantProjection;
  readonly looksProductVariants: (BoxPreviewProductVariantProjection | undefined)[][];
  readonly replacedFor: ReplaceableByProjection;
  readonly replaceableBy: ReplaceableByProjection[];
  readonly onChangedColor: (productVariantId: string) => void;
  readonly onImageView: OnImageViewFunction;
  readonly onBack: () => void;
}
const ItemDetail: FC<ItemDetailProps> = ({
  productVariant,
  looksProductVariants,
  replacedFor,
  replaceableBy,
  onChangedColor,
  onImageView,
  onBack,
}) => {
  const buttonText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.DETAIL_PAGE_COLOR_BUTTON });

  const [selected, setSelected] = useState<ReplaceableByProjection>(replacedFor);
  const selectedRef = useRef(replacedFor);
  useEffect(() => {
    if (selectedRef.current === replacedFor) {
      return;
    }

    setSelected(replacedFor);
    selectedRef.current = replacedFor;
  }, [replacedFor]);
  const handleOnChanged = useCallback((productVariant: ReplaceableByProjection) => setSelected(productVariant), []);
  const handleOnConfirm = useCallback(() => onChangedColor(selected.id), [onChangedColor, selected.id]);

  const screenSize = useScreenSize();
  const isDesktop = screenSize === "L";
  const containerStyle = isDesktop ? style.containerLarge : style.containerSmall;
  const colorPickerContainerStyle = isDesktop ? style.colorPickerContainerLarge : style.colorPickerContainerSmall;
  const galleryContainerStyle = isDesktop ? style.galleryContainerLarge : style.galleryContainerSmall;

  return (
    <Layout
      footerVisible={!isDesktop && selected.id !== replacedFor.id}
      header={<ItemDetailHeader onBack={onBack} />}
      footer={
        <View style={style.buttonContainer}>
          <Button style={style.button} testID="color-picker-button" onPress={handleOnConfirm}>
            {buttonText}
          </Button>
        </View>
      }
      stickyHeader
    >
      <View style={containerStyle} testID="item-detail">
        <View style={galleryContainerStyle}>
          <ProductVariantGallery
            looksProductVariants={looksProductVariants}
            productVariant={{ ...productVariant, media: selected.media }}
            onImageView={onImageView}
          />
        </View>

        <View style={colorPickerContainerStyle}>
          <ColorPicker replaceableBy={replaceableBy} replacedFor={selected} onChanged={handleOnChanged} />

          {isDesktop && selected.id !== replacedFor.id && (
            <Button testID="color-picker-button" onPress={handleOnConfirm}>
              {buttonText}
            </Button>
          )}
        </View>
      </View>
    </Layout>
  );
};

export { ItemDetail };
