import React, { FC, useCallback, useMemo, useRef, useState } from "react";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import { COLOR, Text } from "@lookiero/aurora";
import {
  AspectRatioView,
  Bullets,
  Carousel,
  CarouselStyle,
  RenderBulletsFunction,
  RenderItemFunction,
} from "@lookiero/sty-psp-ui";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import {
  MediaPerspective,
  MediaProjection,
  mainTransparentImageFromMedia,
} from "../../../../../../../projection/media/media";
import { BoxPreviewImageViewTrackerParams } from "../../../../../../tracking/tracking";
import { Checkbox } from "../../../../atoms/checkbox/Checkbox";
import { Price } from "../../../../atoms/price/Price";
import { LookProductVariants } from "../../../looksProductVariants/components/LookProductVariants";
import {
  ProductVariant,
  ProductVariantOnChosenFunction,
  ProductVariantOnPressFunction,
} from "../../../productVariant/ProductVariant";
import { style } from "./GarmentProductVariant.style";

const isMedia = (
  question: MediaProjection | (BoxPreviewWithFeedbackProductVariantProjection | undefined)[],
): question is MediaProjection => Boolean((question as MediaProjection).url);

interface ToLookProductVariantsFunction {
  (
    lookProductVariants: (BoxPreviewWithFeedbackProductVariantProjection | undefined)[],
  ): (BoxPreviewWithFeedbackProductVariantProjection | undefined)[];
}
const toLookProductVariants: ToLookProductVariantsFunction = (lookProductVariants) =>
  lookProductVariants.map(
    (productVariant) =>
      productVariant && {
        ...productVariant,
        media: [mainTransparentImageFromMedia(productVariant.replacedFor?.media || productVariant.media)],
      },
  );

interface HandleOnImageViewFunctionArgs {
  readonly productVariantId: string;
  readonly perspective: MediaPerspective;
  readonly look?: string[];
  readonly lookProductVariantId?: string;
}
interface HandleOnImageViewFunction {
  (args: HandleOnImageViewFunctionArgs, index: number): boolean;
}

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface ProductVariantStyle {
  readonly container: StyleProp<ViewStyle>;
  readonly carousel: CarouselStyle;
}

interface GarmentProductVariantProps {
  readonly productVariant: BoxPreviewWithFeedbackProductVariantProjection;
  readonly looksProductVariants: (BoxPreviewWithFeedbackProductVariantProjection | undefined)[][];
  readonly style?: Partial<ProductVariantStyle>;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: OnImageViewFunction;
}
const GarmentProductVariant: FC<GarmentProductVariantProps> = ({
  productVariant,
  looksProductVariants,
  style: customStyle,
  onChosen,
  onPress,
  onImageView,
}) => {
  const data = useMemo(
    () => [...productVariant.media, ...looksProductVariants],
    [looksProductVariants, productVariant.media],
  );

  const swipeRef = useRef(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleOnActiveChanged = useCallback((activeIndex: number) => {
    setActiveIndex(activeIndex);
    swipeRef.current = true;
  }, []);

  const handleOnPress = useCallback(
    () =>
      onPress?.({
        productVariantId: productVariant.id,
        origin: "image",
      }),
    [onPress, productVariant.id],
  );

  const handleOnPressDescription = useCallback(() => {
    onPress?.({
      productVariantId: productVariant.id,
      origin: "description",
    });
  }, [onPress, productVariant.id]);

  const handleOnChangeCheckbox = useCallback(
    () =>
      onChosen?.({
        productVariantId: productVariant.id,
        chosen: !productVariant.chosen,
        isCandidate: productVariant.isCandidate,
      }),
    [onChosen, productVariant.chosen, productVariant.id, productVariant.isCandidate],
  );

  const handleOnImageView: HandleOnImageViewFunction = useCallback(
    ({ perspective, look, lookProductVariantId, productVariantId }, index) => {
      if (activeIndex === index) {
        onImageView?.({
          perspective,
          productVariantId,
          look,
          lookProductVariantId,
          swipe: swipeRef.current,
        });

        return true;
      }

      return false;
    },
    [activeIndex, onImageView],
  );

  const renderItem: RenderItemFunction<
    MediaProjection | (BoxPreviewWithFeedbackProductVariantProjection | undefined)[]
  > = useCallback(
    ({ item, index }) =>
      isMedia(item) ? (
        <ProductVariant
          key={index}
          chosen={productVariant.chosen}
          isCandidate={productVariant.isCandidate}
          media={item}
          productVariantId={productVariant.id}
          onImageView={(event) => handleOnImageView(event, index)}
        />
      ) : (
        <AspectRatioView key={index} aspectRatio={1.25}>
          <LookProductVariants
            lookProductVariants={toLookProductVariants(item)}
            onImageView={(event) => handleOnImageView(event, index)}
            onPress={onPress}
          />
        </AspectRatioView>
      ),
    [handleOnImageView, onPress, productVariant.chosen, productVariant.id, productVariant.isCandidate],
  );

  const renderBullets: RenderBulletsFunction = useCallback(
    ({ activeIndex, count, onChange }) => <Bullets activeIndex={activeIndex} count={count} onChange={onChange} />,
    [],
  );

  return (
    <View style={[style.container, customStyle?.container]} testID="product-variant">
      <Carousel
        activeIndex={activeIndex}
        bullets={renderBullets}
        data={data}
        style={{ container: customStyle?.carousel?.container }}
        onActiveIndexChanged={handleOnActiveChanged}
        onPress={handleOnPress}
      >
        {renderItem}
      </Carousel>

      {onChosen && (
        <Pressable style={style.checkbox} onPress={handleOnChangeCheckbox}>
          <Checkbox
            checked={productVariant.chosen as boolean}
            value={productVariant.id}
            onChange={handleOnChangeCheckbox}
          />
        </Pressable>
      )}

      <Pressable style={style.description} testID="product-variant-description" onPress={handleOnPressDescription}>
        <Text color={COLOR.TEXT_MEDIUM} level={2} detail>
          {productVariant.brand}
        </Text>

        <Text level={2} detail>
          {productVariant.title}
        </Text>

        {productVariant.price && <Price price={productVariant.price} />}
      </Pressable>
    </View>
  );
};

export { GarmentProductVariant, toLookProductVariants };
