import React, { FC, memo } from "react";
import { Outlet, useRoutes } from "react-router";
import { I18n } from "@lookiero/i18n-react";
import { App } from "../views/App";
import { Expired } from "../views/expired/Expired";
import { Feedback } from "../views/feedback/Feedback";
import { ItemDetail } from "../views/itemDetail/ItemDetail";
import { Items } from "../views/items/Items";
import { ItemsTabs } from "../views/itemsTabs/ItemsTabs";
import { NotFoundStatic } from "../views/notFound/NotFoundStatic";
import { Submitted } from "../views/submitted/Submitted";
import { Routes, Slugs } from "./routes";

interface RoutingProps {
  readonly I18n: I18n;
  readonly onI18nError?: (error: Error) => void;
}

const Routing: FC<RoutingProps> = ({ I18n, onI18nError }) =>
  useRoutes([
    {
      path: Routes.HOME,
      element: (
        <App I18n={I18n} onI18nError={onI18nError}>
          <Outlet />
        </App>
      ),
      children: [
        {
          path: Slugs.EXPIRED,
          element: <Expired />,
        },
        {
          path: Slugs.SUBMITTED,
          element: <Submitted />,
        },
        {
          path: Slugs.FEEDBACK,
          element: <Feedback />,
        },
        {
          path: Slugs.ITEMS,
          element: <Outlet />,
          children: [
            {
              path: "",
              element: (
                <Items>
                  <Outlet />
                </Items>
              ),
              children: [
                {
                  path: Slugs.ITEMS_TABS,
                  element: (
                    <ItemsTabs>
                      <Outlet />
                    </ItemsTabs>
                  ),
                  children: [
                    {
                      path: Slugs.ITEM,
                      element: <ItemDetail />,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundStatic />,
    },
  ]);

/**
 * Provided useRoutes is not stable (when integrated with Sentry) as it's rendering a different component tree.
 *
 * https://github.com/getsentry/sentry-javascript/blob/master/packages/react/src/reactrouterv6.tsx#L221
 * (SentryRoutes is a new component after each re-render)
 */
const MemoizedRouting = memo(Routing);
export { MemoizedRouting as Routing };
