import {
  AggregateRoot,
  RepositoryGetFunction,
  RepositoryGetFunctionArgs,
  RepositorySaveFunction,
  RepositorySaveFunctionArgs,
} from "@lookiero/messaging";
import { BuildBootstrapFunctionReturn, bootstrap as messagingBootstrap } from "@lookiero/messaging-react";
import {
  bootstrapWithBuilder as bootstrapNotifications,
  inMemoryStorageNotifications,
} from "@lookiero/sty-psp-notifications";
import { asyncStorageUiSettings, bootstrapWithBuilder as bootstrapUiSettings } from "@lookiero/sty-psp-ui-settings";
import { SUBMIT_BOX_PREVIEW } from "../../domain/boxPreview/command/submitBoxPreview";
import { createNotificationWhenBoxPreviewExpired } from "../../domain/boxPreview/event/createNotificationWhenBoxPreviewExpired";
import { createNotificationWhenBoxPreviewMaxChosenProductsReached } from "../../domain/boxPreview/event/createNotificationWhenBoxPreviewMaxChosenProductsReached";
import { removeBoxPreviewFeedbackWhenBoxPreviewSubmitted } from "../../domain/boxPreview/event/removeBoxPreviewFeedbackWhenBoxPreviewSubmitted";
import { BoxPreview, submitBoxPreviewHandler } from "../../domain/boxPreview/model/boxPreview";
import { BOX_PREVIEW_EXPIRED } from "../../domain/boxPreview/model/boxPreviewExpired";
import { BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED } from "../../domain/boxPreview/model/boxPreviewMaxChosenProductsReached";
import { BOX_PREVIEW_SUBMITTED } from "../../domain/boxPreview/model/boxPreviewSubmitted";
import { BoxPreviewsGetFunction, BoxPreviewsSaveFunction } from "../../domain/boxPreview/model/boxPreviews";
import { REMOVE_BOX_PREVIEW_FEEDBACK } from "../../domain/boxPreviewFeedback/command/removeBoxPreviewFeedback";
import { UPDATE_BOX_PREVIEW_FEEDBACK } from "../../domain/boxPreviewFeedback/command/updateBoxPreviewFeedback";
import { createNotificationWhenBoxPreviewFeedbackMaxChosenProductsReached } from "../../domain/boxPreviewFeedback/event/createNotificationWhenBoxPreviewFeedbackMaxChosenProductsReached";
import {
  BoxPreviewFeedback,
  removeBoxPreviewFeedbackHandler,
  updateBoxPreviewFeedbackHandler,
} from "../../domain/boxPreviewFeedback/model/boxPreviewFeedback";
import { BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED } from "../../domain/boxPreviewFeedback/model/boxPreviewFeedbackMaxChosenProductsReached";
import {
  BoxPreviewFeedbacksGetFunction,
  BoxPreviewFeedbacksSaveFunction,
} from "../../domain/boxPreviewFeedback/model/boxPreviewFeedbacks";
import {
  BoxPreviewByIdView,
  VIEW_BOX_PREVIEW_BY_ID,
  viewBoxPreviewByIdHandler,
} from "../../projection/boxPreview/viewBoxPreviewById";
import {
  BoxPreviewFeedbackByBoxPreviewIdView,
  VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID,
  viewBoxPreviewFeedbackByBoxPreviewIdHandler,
} from "../../projection/boxPreviewFeedback/viewBoxPreviewFeedbackByBoxPreviewId";

const MESSAGING_CONTEXT_ID = "BoxPreview";

type NeverWhenEmptyRecord<K extends [Record<string, unknown>]> = K extends [Record<string, never>] ? [never?] : K;

type RepositoryDependencies<
  A extends AggregateRoot,
  GetFunctionArgs extends RepositoryGetFunctionArgs,
  SaveFunctionArgs extends RepositorySaveFunctionArgs,
> = Omit<
  Parameters<RepositoryGetFunction<A, GetFunctionArgs>>[0] & Parameters<RepositorySaveFunction<A, SaveFunctionArgs>>[0],
  keyof RepositoryGetFunctionArgs
>;

interface BaseBootstrapFunctionArgs<
  BoxPreviewGetFunctionArgs extends RepositoryGetFunctionArgs,
  BoxPreviewSaveFunctionArgs extends RepositorySaveFunctionArgs,
  BoxPreviewFeedbackGetFunctionArgs extends RepositoryGetFunctionArgs,
  BoxPreviewFeedbackSaveFunctionArgs extends RepositorySaveFunctionArgs,
> {
  readonly boxPreviewByIdView: BoxPreviewByIdView;
  readonly boxPreviewFeedbackByBoxPreviewIdView: BoxPreviewFeedbackByBoxPreviewIdView;
  readonly getBoxPreview: BoxPreviewsGetFunction<BoxPreviewGetFunctionArgs>;
  readonly saveBoxPreview: BoxPreviewsSaveFunction<BoxPreviewSaveFunctionArgs>;
  readonly boxPreviewsDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<BoxPreview, BoxPreviewGetFunctionArgs, BoxPreviewSaveFunctionArgs>]
  >;
  readonly getBoxPreviewFeedback: BoxPreviewFeedbacksGetFunction<BoxPreviewFeedbackGetFunctionArgs>;
  readonly saveBoxPreviewFeedback: BoxPreviewFeedbacksSaveFunction<BoxPreviewFeedbackSaveFunctionArgs>;
  readonly boxPreviewFeedbacksDependencies: NeverWhenEmptyRecord<
    [RepositoryDependencies<BoxPreviewFeedback, BoxPreviewFeedbackGetFunctionArgs, BoxPreviewFeedbackSaveFunctionArgs>]
  >;
}

interface BaseBootstrapFunction {
  <
    BoxPreviewGetFunctionArgs extends RepositoryGetFunctionArgs,
    BoxPreviewSaveFunctionArgs extends RepositorySaveFunctionArgs,
    BoxPreviewFeedbackGetFunctionArgs extends RepositoryGetFunctionArgs,
    BoxPreviewFeedbackSaveFunctionArgs extends RepositorySaveFunctionArgs,
  >(
    args: BaseBootstrapFunctionArgs<
      BoxPreviewGetFunctionArgs,
      BoxPreviewSaveFunctionArgs,
      BoxPreviewFeedbackGetFunctionArgs,
      BoxPreviewFeedbackSaveFunctionArgs
    >,
  ): BuildBootstrapFunctionReturn;
}

const baseBootstrap: BaseBootstrapFunction = ({
  boxPreviewByIdView,
  boxPreviewFeedbackByBoxPreviewIdView,
  getBoxPreview,
  saveBoxPreview,
  boxPreviewsDependencies,
  getBoxPreviewFeedback,
  saveBoxPreviewFeedback,
  boxPreviewFeedbacksDependencies,
}) => {
  let messaging = messagingBootstrap({ id: MESSAGING_CONTEXT_ID })
    .query(VIEW_BOX_PREVIEW_BY_ID, viewBoxPreviewByIdHandler, {
      view: boxPreviewByIdView,
    })
    .query(VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID, viewBoxPreviewFeedbackByBoxPreviewIdHandler, {
      view: boxPreviewFeedbackByBoxPreviewIdView,
    })
    .command(SUBMIT_BOX_PREVIEW, submitBoxPreviewHandler)(getBoxPreview, saveBoxPreview, ...boxPreviewsDependencies)
    .command(UPDATE_BOX_PREVIEW_FEEDBACK, updateBoxPreviewFeedbackHandler)(
      getBoxPreviewFeedback,
      saveBoxPreviewFeedback,
      ...boxPreviewFeedbacksDependencies,
    )
    .command(REMOVE_BOX_PREVIEW_FEEDBACK, removeBoxPreviewFeedbackHandler)(
      getBoxPreviewFeedback,
      saveBoxPreviewFeedback,
      ...boxPreviewFeedbacksDependencies,
    )
    .processManager(
      BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED,
      createNotificationWhenBoxPreviewFeedbackMaxChosenProductsReached,
    )
    .processManager(BOX_PREVIEW_EXPIRED, createNotificationWhenBoxPreviewExpired)
    .processManager(BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED, createNotificationWhenBoxPreviewMaxChosenProductsReached)
    .processManager(BOX_PREVIEW_SUBMITTED, removeBoxPreviewFeedbackWhenBoxPreviewSubmitted);

  messaging = bootstrapNotifications({ messaging, storage: inMemoryStorageNotifications() });

  messaging = bootstrapUiSettings({ messaging, storage: asyncStorageUiSettings() });

  return messaging.build();
};

export { MESSAGING_CONTEXT_ID, baseBootstrap };
