import React, { FC } from "react";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { mainImageFromMedia } from "../../../../../../projection/media/media";
import { Look, LookStyle } from "../../../templates/look/Look";
import {
  ProductVariant,
  ProductVariantOnChosenFunction,
  ProductVariantOnImageViewFunction,
  ProductVariantOnPressFunction,
} from "../../productVariant/ProductVariant";

interface LookProductVariantsStyle {
  readonly look: Partial<LookStyle>;
}

interface LookProductVariantsProps {
  readonly children?: unknown;
  readonly lookProductVariants: (BoxPreviewWithFeedbackProductVariantProjection | undefined)[];
  readonly mediaSize?: number;
  readonly style?: Partial<LookProductVariantsStyle>;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: ProductVariantOnImageViewFunction;
}
const LookProductVariants: FC<LookProductVariantsProps> = ({
  lookProductVariants,
  mediaSize,
  style: customStyle,
  onChosen,
  onPress,
  onImageView,
}) => (
  <Look lookProductVariants={lookProductVariants} style={customStyle?.look}>
    {(lookProductVariant: BoxPreviewWithFeedbackProductVariantProjection | undefined) =>
      lookProductVariant && (
        <ProductVariant
          key={lookProductVariant.id}
          chosen={lookProductVariant.chosen}
          isCandidate={lookProductVariant.isCandidate}
          media={mainImageFromMedia(lookProductVariant.media)}
          mediaSize={mediaSize}
          productVariantId={lookProductVariant.id}
          onChosen={onChosen}
          onPress={onPress}
          onImageView={({ perspective, productVariantId, swipe }) =>
            onImageView({
              perspective,
              productVariantId,
              look: lookProductVariants.map((productVariant) => productVariant?.id) as string[],
              lookProductVariantId: lookProductVariant.id,
              swipe,
            })
          }
        />
      )
    }
  </Look>
);

export { LookProductVariants };
