import React, { FC } from "react";
import { useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { useViewBoxPreviewById } from "../../../projection/boxPreview/react/useViewBoxPreviewById";
import { TrackingPage } from "../../../tracking/tracking";
import { usePageView } from "../../../tracking/usePageView";
import { Expired as ExpiredTemplate } from "../../components/templates/expired/Expired";

const Expired: FC = () => {
  const { id } = useParams();
  const [boxPreview] = useViewBoxPreviewById({ boxPreviewId: id as string });

  usePageView({ country: boxPreview?.countryCode, page: TrackingPage.EXPIRED, boxPreviewId: boxPreview?.id });

  if (!boxPreview) {
    return <Spinner />;
  }

  return <ExpiredTemplate date={boxPreview.expiration} />;
};

export { Expired };
