import camelcaseKeys from "camelcase-keys";
import { HttpGetFunction } from "@lookiero/sty-psp-http";
import { EnvironmentProjection } from "../../../../projection/environment/environment";

type EnvironmentFetcherResult = EnvironmentProjection | null;

interface HttpEnvironmentFetcherFunctionArgs {
  readonly httpGet: HttpGetFunction;
}

interface HttpEnvironmentFetcherFunction {
  (args: HttpEnvironmentFetcherFunctionArgs): Promise<EnvironmentFetcherResult>;
}

const httpEnvironmentFetcher: HttpEnvironmentFetcherFunction = async ({ httpGet }) =>
  await httpGet<EnvironmentProjection, EnvironmentFetcherResult>({
    endpoint: `/config.json?${Date.now()}`,
    result: {
      error: null,
      success: (environment: EnvironmentProjection) =>
        camelcaseKeys<EnvironmentProjection>(environment, { deep: true }),
    },
  });

export { httpEnvironmentFetcher };
