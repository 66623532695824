import loadjs from "loadjs";
import {
  Tracker,
  TrackerBoxPreviewImageViewParams,
  TrackerLooksViewParams,
  TrackerPageViewParams,
  TrackerProductDetailParams,
  TrackerChosenProductVariantParams,
  TrackerAssignedVariationByExperimentParams,
  TrackerSubmitBoxPreviewParams,
  TrackerColorChangedParams,
  TrackerColorAvailableParams,
  TrackerClickItemParams,
} from "./tracking";

declare global {
  interface Window {
    dataLayer: {
      readonly push: (data: unknown) => Promise<void>;
    };
  }
}

class AsyncGtmTracker implements Tracker {
  private project: string;

  private constructor(project: string) {
    this.project = project;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ["gtm.start"]: new Date().getTime(), event: "gtm.js" });
  }

  public static async init({ project, gtmId }: { project: string; gtmId: string }): Promise<AsyncGtmTracker> {
    try {
      await loadjs(`https://www.googletagmanager.com/gtm.js?id=${gtmId}`, { returnPromise: true });
    } catch (error) {}

    return new AsyncGtmTracker(project);
  }

  private push(event: Record<string, unknown>): void {
    if (window.dataLayer) {
      window.dataLayer.push(event);
    }
  }

  public async pageView({ page, country, boxPreviewId }: TrackerPageViewParams): Promise<void> {
    this.push({
      event: "pageview",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
    });
  }

  public async productDetailView({
    page,
    country,
    boxPreviewId,
    productVariantId,
  }: TrackerProductDetailParams): Promise<void> {
    this.push({
      event: "box-preview.interstitial",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      productVariantId,
      boxPreviewId,
    });
  }

  public async looksView({ page, country, boxPreviewId }: TrackerLooksViewParams): Promise<void> {
    this.push({
      event: "box-preview-looks",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
    });
  }

  public async boxPreviewImageView({
    page,
    country,
    boxPreviewId,
    productVariantId,
    perspective,
    look,
    lookProductVariantId,
    swipe,
  }: TrackerBoxPreviewImageViewParams): Promise<void> {
    this.push({
      event: "box-preview_image-view",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      productVariantId,
      perspective,
      look,
      lookProductVariantId,
      swipe,
    });
  }

  public async chosenProductVariant({
    page,
    country,
    boxPreviewId,
    productVariantId,
    chosen,
    look,
    isCandidate,
    auto,
  }: TrackerChosenProductVariantParams): Promise<void> {
    this.push({
      event: "box-preview.chosen-product-variant",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      productVariantId,
      chosen,
      look,
      isCandidate,
      auto,
    });
  }

  public async assignedVariationByExperiment({
    page,
    country,
    boxPreviewId,
    customerId,
    experiment,
    variation,
  }: TrackerAssignedVariationByExperimentParams): Promise<void> {
    this.push({
      event: "box-preview_abtest",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      userId: customerId,
      experiment,
      variation,
    });
  }

  public async submitBoxPreview({
    page,
    country,
    boxPreviewId,
    customerId,
    items,
  }: TrackerSubmitBoxPreviewParams): Promise<void> {
    this.push({
      event: "box-preview_submit",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      userId: customerId,
      items,
    });
  }

  public async colorChanged({
    page,
    country,
    boxPreviewId,
    productVariantId,
    replacedFor,
  }: TrackerColorChangedParams): Promise<void> {
    this.push({
      event: "box-preview_color-changed",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      productVariantId,
      replacedFor,
    });
  }

  public async colorAvailable({
    page,
    country,
    boxPreviewId,
    productVariantId,
    available,
    replaceableNumber,
  }: TrackerColorAvailableParams): Promise<void> {
    this.push({
      event: "box-preview_color-available",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      productVariantId,
      available,
      replaceableNumber,
    });
  }

  public async clickItem({
    page,
    country,
    boxPreviewId,
    productVariantId,
    origin,
  }: TrackerClickItemParams): Promise<void> {
    this.push({
      event: "box_preview_click_item",
      eventCategory: "navigation",
      section: `${this.project}_${page}`,
      store: country,
      boxPreviewId,
      productVariantId,
      origin,
    });
  }
}

export { AsyncGtmTracker };
