import { useLayoutEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface UsePageViewFunctionArgs {
  readonly country?: Country;
  readonly page: TrackingPage;
  readonly boxPreviewId?: string;
}

interface UsePageViewFunction {
  (args: UsePageViewFunctionArgs): void;
}

const usePageView: UsePageViewFunction = ({ country, page, boxPreviewId }) => {
  const tracker = useTracker();

  useLayoutEffect(() => {
    if (!(tracker && country && page && boxPreviewId)) {
      return;
    }

    tracker.pageView({ country, page, boxPreviewId });
  }, [boxPreviewId, country, page, tracker]);
};

export { usePageView };
