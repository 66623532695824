import { useCallback } from "react";
import { ChosenProductVariantTrackerParams } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackChosenProductVariantFunction {
  (args: ChosenProductVariantTrackerParams): void;
}

interface UseChosenProductVariantFunctionArgs {
  readonly country?: string;
  readonly boxPreviewId?: string;
}

interface UseChosenProductVariantFunction {
  (args: UseChosenProductVariantFunctionArgs): TrackChosenProductVariantFunction;
}

const useChosenProductVariant: UseChosenProductVariantFunction = ({ country, boxPreviewId }) => {
  const tracker = useTracker();

  const trackChosenProductVariant: TrackChosenProductVariantFunction = useCallback(
    ({ page, productVariantId, chosen, look, isCandidate, auto }) => {
      if (!(tracker && country && page && boxPreviewId && productVariantId)) {
        return;
      }

      tracker.chosenProductVariant({ country, page, boxPreviewId, productVariantId, chosen, look, isCandidate, auto });
    },
    [tracker, country, boxPreviewId],
  );

  return trackChosenProductVariant;
};

export { useChosenProductVariant };
