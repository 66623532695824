import { MediaPerspective } from "../../projection/media/media";

enum TrackingPage {
  EXPIRED = "expired",
  ITEMS = "items",
  ITEM_DETAIL = "item-detail",
  FEEDBACK = "feedback",
  THANK_YOU = "thank-you",
  LOOKS = "looks",
}

interface BaseTrackerParams {
  readonly country: string;
  readonly page: TrackingPage;
  readonly boxPreviewId: string;
}

type TrackerPageViewParams = BaseTrackerParams;

interface TrackerProductDetailParams extends BaseTrackerParams {
  readonly productVariantId: string;
}

type TrackerLooksViewParams = BaseTrackerParams;

interface BoxPreviewImageViewTrackerParams {
  readonly page?: TrackingPage;
  readonly productVariantId: string;
  readonly perspective: MediaPerspective;
  readonly look?: string[];
  readonly lookProductVariantId?: string;
  readonly swipe?: boolean;
}

type TrackerBoxPreviewImageViewParams = BaseTrackerParams & BoxPreviewImageViewTrackerParams;

interface ChosenProductVariantTrackerParams {
  readonly page: TrackingPage;
  readonly productVariantId: string;
  readonly chosen: boolean;
  readonly look?: string[];
  readonly isCandidate: boolean;
  readonly auto: boolean;
}

type TrackerChosenProductVariantParams = BaseTrackerParams & ChosenProductVariantTrackerParams;

interface TrackerAssignedVariationByExperimentParams extends BaseTrackerParams {
  readonly customerId: string;
  readonly experiment: string;
  readonly variation: string | undefined;
}

interface TrackerSubmitBoxPreviewParams extends BaseTrackerParams {
  readonly customerId: string;
  readonly items: string[];
}

interface TrackerColorChangedParams extends BaseTrackerParams {
  readonly productVariantId: string;
  readonly replacedFor: string;
}

interface TrackerColorAvailableParams extends BaseTrackerParams {
  readonly productVariantId: string;
  readonly available: string[];
  readonly replaceableNumber: number;
}

type TrackerClickItemOrigin = "image" | "description" | "more_colors";
interface TrackerClickItemParams extends BaseTrackerParams {
  readonly productVariantId: string;
  readonly origin: TrackerClickItemOrigin;
}

interface Tracker {
  pageView: (params: TrackerPageViewParams) => Promise<void>;
  productDetailView: (params: TrackerProductDetailParams) => Promise<void>;
  looksView: (params: TrackerLooksViewParams) => Promise<void>;
  boxPreviewImageView: (params: TrackerBoxPreviewImageViewParams) => Promise<void>;
  chosenProductVariant: (params: TrackerChosenProductVariantParams) => Promise<void>;
  assignedVariationByExperiment: (params: TrackerAssignedVariationByExperimentParams) => Promise<void>;
  submitBoxPreview: (params: TrackerSubmitBoxPreviewParams) => Promise<void>;
  colorChanged: (params: TrackerColorChangedParams) => Promise<void>;
  colorAvailable: (params: TrackerColorAvailableParams) => Promise<void>;
  clickItem: (params: TrackerClickItemParams) => Promise<void>;
}

export type {
  BoxPreviewImageViewTrackerParams,
  ChosenProductVariantTrackerParams,
  TrackerClickItemOrigin,
  Tracker,
  TrackerAssignedVariationByExperimentParams,
  TrackerBoxPreviewImageViewParams,
  TrackerChosenProductVariantParams,
  TrackerClickItemParams,
  TrackerColorAvailableParams,
  TrackerColorChangedParams,
  TrackerLooksViewParams,
  TrackerPageViewParams,
  TrackerProductDetailParams,
  TrackerSubmitBoxPreviewParams,
};
export { TrackingPage };
