import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2, space6 } = theme();

const style = StyleSheet.create({
  bodyRow: {
    flex: 0,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
    gap: space2,
    justifyContent: "center",
    padding: space6,
  },
});

export { style };
