import React, { FC, ReactNode, useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { BoxPreviewProductVariantProjection } from "../../../../../projection/boxPreview/boxPreview";
import { style } from "./Look.style";

interface LookItemProps {
  readonly children: ReactNode;
  readonly style: StyleProp<ViewStyle>;
}
const LookItem: FC<LookItemProps> = ({ children, style }) => (
  <View style={style} testID="look">
    {children}
  </View>
);

interface LookStyle {
  readonly lookTemplate: StyleProp<ViewStyle>;
  readonly lookItem: StyleProp<ViewStyle>;
}

interface LookProps {
  readonly lookProductVariants: (BoxPreviewProductVariantProjection | undefined)[];
  readonly children: (lookProductVariant: BoxPreviewProductVariantProjection | undefined, index: number) => ReactNode;
  readonly style?: Partial<LookStyle>;
}
const Look: FC<LookProps> = ({ lookProductVariants, children, style: customStyle }) => {
  const templateEven = useMemo(
    () => (
      <View style={[style.lookTemplate, customStyle?.lookTemplate]} testID="look-template-even">
        {lookProductVariants.map((item, index) => (
          <LookItem key={index} style={[style.lookTemplateEven, customStyle?.lookItem]}>
            {children(item, index)}
          </LookItem>
        ))}
      </View>
    ),
    [children, customStyle, lookProductVariants],
  );

  const template3 = useMemo(
    () => (
      <View style={[style.lookTemplate, customStyle?.lookTemplate]} testID="look-template-three">
        <LookItem style={[style.lookTemplateThree, style.lookTemplateThreeLeft, customStyle?.lookItem]}>
          {children(lookProductVariants[0], 0)}
        </LookItem>
        <View style={style.lookTemplateColumn}>
          <LookItem style={[style.lookTemplateThree, customStyle?.lookItem]}>
            {children(lookProductVariants[1], 1)}
          </LookItem>
          <LookItem style={[style.lookTemplateThree, customStyle?.lookItem]}>
            {children(lookProductVariants[2], 2)}
          </LookItem>
        </View>
      </View>
    ),
    [children, customStyle, lookProductVariants],
  );

  return lookProductVariants.length === 3 ? template3 : templateEven;
};

export type { LookStyle };
export { Look };
