import React, { FC } from "react";
import Svg, { Rect } from "react-native-svg";

interface RectangleProps {
  readonly width: number;
  readonly height: number;
}

const Rectangle: FC<RectangleProps> = ({ width, height }) => (
  <Svg fill="none" height={height} viewBox="0 0 198 312" width={width}>
    <Rect fill="#E8CC42" height="312" rx="99" width="198" />
  </Svg>
);

export { Rectangle };
