import { useLayoutEffect } from "react";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface UsePageViewFunctionArgs {
  readonly country?: string;
  readonly page: TrackingPage;
  readonly boxPreviewId?: string;
  readonly productVariantId?: string;
}

interface UsePageViewFunction {
  (args: UsePageViewFunctionArgs): void;
}

const useProductDetailView: UsePageViewFunction = ({ country, page, boxPreviewId, productVariantId }) => {
  const tracker = useTracker();

  useLayoutEffect(() => {
    if (!(tracker && country && page && boxPreviewId && productVariantId)) {
      return;
    }

    tracker.productDetailView({ country, page, boxPreviewId, productVariantId });
  }, [boxPreviewId, country, page, productVariantId, tracker]);
};

export { useProductDetailView };
