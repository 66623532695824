import { Platform } from "react-native";
import { BuildBootstrapFunctionReturn } from "@lookiero/messaging-react";
import { fetchHttpPost } from "@lookiero/sty-psp-http";
import { asyncStorageStorage } from "@lookiero/sty-psp-storage";
import { VERSION } from "../../version";
import { getBoxPreview, saveBoxPreview } from "../domain/boxPreview/model/httpBoxPreviews";
import {
  getBoxPreviewFeedback,
  saveBoxPreviewFeedback,
} from "../domain/boxPreviewFeedback/model/storageBoxPreviewFeedbacks";
import { BoxPreviewFeedbackDto } from "../persistence/boxPreviewFeedbackDto";
import { httpBoxPreviewByIdView } from "../projection/boxPreview/model/httpBoxPreviewByIdView";
import { storageBoxPreviewFeedbackByBoxPreviewIdView } from "../projection/boxPreviewFeedback/model/storageBoxPreviewFeedbackByBoxPreviewIdView";
import { baseBootstrap } from "./baseBootstrap";

type OS = typeof Platform.OS;
type Device = Exclude<OS, "macos" | "windows">;
const device = Platform.OS as Device;

interface BootstrapFunctionArgs {
  readonly apiUrl: () => string;
}

interface BootstrapFunction {
  (args: BootstrapFunctionArgs): BuildBootstrapFunctionReturn;
}

const bootstrap: BootstrapFunction = ({ apiUrl }) => {
  const httpPost = fetchHttpPost({ apiUrl, device, version: VERSION });
  const boxPreviewStorage = asyncStorageStorage<BoxPreviewFeedbackDto>();

  return baseBootstrap({
    boxPreviewByIdView: httpBoxPreviewByIdView({ httpPost }),
    boxPreviewFeedbackByBoxPreviewIdView: storageBoxPreviewFeedbackByBoxPreviewIdView({ storage: boxPreviewStorage }),
    getBoxPreview,
    saveBoxPreview,
    boxPreviewsDependencies: [{ httpPost }],
    getBoxPreviewFeedback,
    saveBoxPreviewFeedback,
    boxPreviewFeedbacksDependencies: [{ storage: boxPreviewStorage }],
  });
};

export { bootstrap };
