import { useCallback } from "react";
import { BoxPreviewImageViewTrackerParams } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackBoxPreviewImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface UseBoxPreviewImageViewFunctionArgs {
  readonly country?: string;
  readonly boxPreviewId?: string;
}

interface UseBoxPreviewImageViewFunction {
  (args: UseBoxPreviewImageViewFunctionArgs): TrackBoxPreviewImageViewFunction;
}

const useBoxPreviewImageView: UseBoxPreviewImageViewFunction = ({ country, boxPreviewId }) => {
  const tracker = useTracker();

  const trackBoxPreviewImageView: TrackBoxPreviewImageViewFunction = useCallback(
    ({ page, productVariantId, perspective, look, lookProductVariantId, swipe }) => {
      if (!(tracker && country && boxPreviewId && page)) {
        return;
      }

      tracker.boxPreviewImageView({
        country,
        page,
        boxPreviewId,
        productVariantId,
        perspective,
        look,
        lookProductVariantId,
        swipe,
      });
    },
    [tracker, country, boxPreviewId],
  );

  return trackBoxPreviewImageView;
};

export { useBoxPreviewImageView };
