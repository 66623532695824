import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgPrimary, space4, space8 } = theme();

const style = StyleSheet.create({
  footer: {
    alignItems: "center",
    backgroundColor: colorBgPrimary,
    flexDirection: "row",
    gap: space4,
    justifyContent: "center",
    paddingVertical: space8,
  },
});

export { style };
