import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space3, space4, space5, space6, space10 } = theme();

const DESKTOP_MAX_WIDTH = 2560;

const style = StyleSheet.create({
  button: {
    height: "100%",
  },
  buttonContainer: {
    flex: 1,
    height: "100%",
    paddingHorizontal: space4,
    paddingVertical: space3,
  },
  colorPickerContainerLarge: {
    flex: 1 / 3,
    gap: space5,
    paddingHorizontal: space10,
    paddingTop: space4,
  },
  colorPickerContainerSmall: {
    gap: space6,
    paddingVertical: space4,
  },
  containerLarge: {
    flexDirection: "row",
    maxWidth: DESKTOP_MAX_WIDTH,
  },
  containerSmall: {
    flexDirection: "column",
    flexGrow: 1,
  },
  galleryContainerLarge: {
    flex: 2 / 3,
  },
  galleryContainerSmall: {
    flex: 1,
  },
});

export { style };
