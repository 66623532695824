import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2 } = theme();

const style = StyleSheet.create({
  bodyColumn: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  text: {
    marginTop: space2,
    textAlign: "center",
  },
});

export { style };
