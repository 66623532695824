import React, { FC, memo } from "react";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { mainTransparentImageFromMedia } from "../../../../../../projection/media/media";
import {
  ProductVariant,
  ProductVariantOnChosenFunction,
  ProductVariantOnImageViewFunction,
  ProductVariantOnPressFunction,
} from "../../productVariant/ProductVariant";

interface OtherGarmentProductVariantProps {
  readonly productVariant: BoxPreviewWithFeedbackProductVariantProjection;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: ProductVariantOnImageViewFunction;
}
const OtherGarmentProductVariant: FC<OtherGarmentProductVariantProps> = ({
  productVariant,
  onChosen,
  onPress,
  onImageView,
}) => (
  <ProductVariant
    chosen={productVariant.chosen}
    isCandidate={productVariant.isCandidate}
    media={mainTransparentImageFromMedia(productVariant.media)}
    productVariantId={productVariant.id}
    onChosen={onChosen}
    onImageView={onImageView}
    onPress={onPress}
  />
);

const areEqual = (prevProps: OtherGarmentProductVariantProps, nextProps: OtherGarmentProductVariantProps): boolean =>
  prevProps.productVariant.id === nextProps.productVariant.id &&
  prevProps.productVariant.chosen === nextProps.productVariant.chosen &&
  prevProps.onChosen === nextProps.onChosen;
const MemorizedOtherGarmentProductVariant = memo(OtherGarmentProductVariant, areEqual);

export { MemorizedOtherGarmentProductVariant as OtherGarmentProductVariant };
