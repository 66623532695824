import React, { FC, useCallback, useEffect, useMemo } from "react";
import { View } from "react-native";
import Animated, {
  Easing,
  Extrapolation,
  interpolate,
  interpolateColor,
  useAnimatedProps,
  useAnimatedStyle,
  useDerivedValue,
  useSharedValue,
  withRepeat,
  withTiming,
} from "react-native-reanimated";
import { Svg, Path } from "react-native-svg";
import { theme } from "@lookiero/sty-psp-ui";
import { style } from "./ShapesSpinner.style";

const AnimatedView = Animated.createAnimatedComponent(View);
const AnimatedSvg = Animated.createAnimatedComponent(Svg);

const { colorBgRose, colorBgSuccess, colorBgWarning } = theme();

const ShapesSpinner: FC = () => {
  const animation = useSharedValue(0);

  useEffect(() => {
    animation.value = withRepeat(
      withTiming(6, {
        duration: 2500,
        easing: Easing.inOut(Easing.cubic),
      }),
      -1,
    );
  });

  const fill = useDerivedValue(
    () =>
      interpolateColor(
        animation.value,
        [0.4, 0.8, 1.6, 4.4, 5.2, 5.6],
        [colorBgRose, colorBgSuccess, colorBgWarning, colorBgWarning, colorBgSuccess, colorBgRose],
      ),
    [animation],
  );
  const containerScale = useDerivedValue(
    () => interpolate(animation.value, [0, 1, 5, 6], [1, 1.25, 1.25, 1], Extrapolation.CLAMP),
    [animation],
  );
  const containerRotation = useDerivedValue(
    () => interpolate(animation.value, [2, 4], [0, 180], Extrapolation.CLAMP),
    [animation],
  );
  const firstShapeY = useDerivedValue(
    () => interpolate(animation.value, [1, 2, 4, 5], [0, -30, -30, 0], Extrapolation.CLAMP),
    [animation],
  );
  const secondShapeY = useDerivedValue(
    () => interpolate(animation.value, [1, 2, 4, 5], [0, 30, 30, 0], Extrapolation.CLAMP),
    [animation],
  );

  const shapeAnimatedProps = useAnimatedProps(() => ({ fill: fill.value }), [fill]);
  const shapesAnimatedStyle = useAnimatedStyle(
    () => ({
      transform: [{ scale: containerScale.value }, { rotate: `${containerRotation.value}deg` }],
    }),
    [containerRotation, containerScale],
  );
  const firstShapeAnimatedStyle = useAnimatedStyle(
    () => ({ transform: [{ translateY: firstShapeY.value }] }),
    [firstShapeY],
  );
  const secondShapeAnimatedStyle = useAnimatedStyle(
    () => ({ transform: [{ translateY: secondShapeY.value }] }),
    [secondShapeY],
  );

  const voidOnPress = useCallback(() => {}, []);
  const path = useMemo(
    () => (
      <Path d="M22.641 3.95868e-06L125.091 2.18716e-05C138.287 2.41788e-05 149 10.7334 149 23.9538L149 27.3578C149 68.5534 115.616 102 74.497 102C33.3843 101.994 -1.19852e-05 68.5474 -4.78234e-06 27.3518L-3.96608e-06 22.6833C-1.77714e-06 10.164 10.1451 1.77382e-06 22.641 3.95868e-06Z" />
    ),
    [],
  );

  return (
    <View style={style.container}>
      <AnimatedView style={[style.shapes, shapesAnimatedStyle]}>
        <AnimatedView style={[style.shape, firstShapeAnimatedStyle]}>
          <AnimatedSvg
            animatedProps={shapeAnimatedProps}
            rotation="180"
            stroke="none"
            style={style.svg}
            viewBox="0 0 149 102"
            onPress={voidOnPress}
          >
            {path}
          </AnimatedSvg>
        </AnimatedView>
        <AnimatedView style={[style.shape, style.shapeBottom, secondShapeAnimatedStyle]}>
          <AnimatedSvg
            animatedProps={shapeAnimatedProps}
            stroke="none"
            style={style.svg}
            viewBox="0 0 149 102"
            onPress={voidOnPress}
          >
            {path}
          </AnimatedSvg>
        </AnimatedView>
      </AnimatedView>
    </View>
  );
};

export { ShapesSpinner };
