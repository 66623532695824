import { ProcessManagerFunction } from "@lookiero/messaging";
import { createToastNotification, NotificationLevel } from "@lookiero/sty-psp-notifications";
import { I18nMessages, PREFIX } from "../../../infrastructure/ui/i18n/i18n";
import { BoxPreviewExpired } from "../model/boxPreviewExpired";

interface CreateNotificationWhenBoxPreviewExpiredFunction extends ProcessManagerFunction<BoxPreviewExpired> {}

const createNotificationWhenBoxPreviewExpired: CreateNotificationWhenBoxPreviewExpiredFunction =
  ({ commandBus }) =>
  async ({ aggregateId }) => {
    await commandBus(
      createToastNotification({
        aggregateId: `BoxPreviewFeedbackSubmitted-${aggregateId}`,
        level: NotificationLevel.ERROR,
        bodyI18nKey: `${PREFIX}${I18nMessages.SUBMIT_PAGE_NOTIFICATION_EXPIRED}`,
      }),
    );
  };

export { createNotificationWhenBoxPreviewExpired };
