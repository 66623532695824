import React, { FC, useCallback } from "react";
import { FlatList } from "react-native";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { mainImageFromMedia } from "../../../../../projection/media/media";
import { ProductVariant } from "../productVariant/ProductVariant";
import { style } from "./SummaryProductVariants.style";

interface SummaryProductVariantsProps {
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[];
}
const SummaryProductVariants: FC<SummaryProductVariantsProps> = ({ productVariants }) => {
  const renderItem = useCallback(
    ({ item }: { item: BoxPreviewWithFeedbackProductVariantProjection }) => (
      <ProductVariant
        chosen={item.chosen}
        isCandidate={item.isCandidate}
        media={mainImageFromMedia(item.replacedFor?.media || item.media)}
        productVariantId={item.id}
        style={style.productVariant}
      />
    ),
    [],
  );

  return (
    <FlatList
      contentContainerStyle={style.contentContainer}
      data={productVariants}
      keyExtractor={(item) => item.id}
      renderItem={renderItem}
      showsHorizontalScrollIndicator={false}
      style={style.container}
      testID="summary-product-variants"
      horizontal
    />
  );
};

export { SummaryProductVariants };
