import { command, Command } from "@lookiero/messaging";

const REMOVE_BOX_PREVIEW_FEEDBACK = "remove_box_preview_feedback";

interface RemoveBoxPreviewFeedbackPayload {
  readonly aggregateId: string;
}

interface RemoveBoxPreviewFeedback
  extends Command<typeof REMOVE_BOX_PREVIEW_FEEDBACK>,
    RemoveBoxPreviewFeedbackPayload {}

interface RemoveBoxPreviewFeedbackFunction {
  (payload: RemoveBoxPreviewFeedbackPayload): RemoveBoxPreviewFeedback;
}

const removeBoxPreviewFeedback: RemoveBoxPreviewFeedbackFunction = ({ aggregateId }) =>
  command({ aggregateId, name: REMOVE_BOX_PREVIEW_FEEDBACK });

export type { RemoveBoxPreviewFeedback as RemoveBoxPreviewFeedback };
export { REMOVE_BOX_PREVIEW_FEEDBACK, removeBoxPreviewFeedback };
