import { domainEvent, DomainEvent } from "@lookiero/messaging";

const BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED = "box_preview_max_chosen_products_reached";

interface BoxPreviewMaxChosenProductsReachedPayload {
  readonly aggregateId: string;
}

interface BoxPreviewMaxChosenProductsReached
  extends DomainEvent<typeof BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED>,
    BoxPreviewMaxChosenProductsReachedPayload {}

interface BoxPreviewMaxChosenProductsReachedFunction {
  (payload: BoxPreviewMaxChosenProductsReachedPayload): BoxPreviewMaxChosenProductsReached;
}

const boxPreviewMaxChosenProductsReached: BoxPreviewMaxChosenProductsReachedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED });

export type { BoxPreviewMaxChosenProductsReached };
export { BOX_PREVIEW_MAX_CHOSEN_PRODUCTS_REACHED, boxPreviewMaxChosenProductsReached };
