import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  container: {
    alignItems: "center",
    height: 200,
    justifyContent: "center",
    width: 200,
  },
  shape: {
    height: 100,
    position: "absolute",
    width: 100,
  },
  shapeBottom: {
    justifyContent: "flex-end",
  },
  shapes: {
    alignItems: "center",
    justifyContent: "center",
    transformOrigin: "center",
  },
  svg: {
    width: 100,
  },
});

export { style };
