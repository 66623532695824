import { useEffect } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { TrackingPage } from "./tracking";
import { useTrackColorAvailable } from "./useTrackColorAvailable";

interface UseTrackProductVariantsColorAvailableFunctionArgs {
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[] | undefined;
  readonly country: Country | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseTrackProductVariantsColorAvailableFunction {
  (args: UseTrackProductVariantsColorAvailableFunctionArgs): void;
}

const useTrackProductVariantsColorAvailable: UseTrackProductVariantsColorAvailableFunction = ({
  productVariants,
  country,
  page,
  boxPreviewId,
}) => {
  const trackColorAvailable = useTrackColorAvailable({
    page,
    country,
    boxPreviewId,
  });

  useEffect(() => {
    if (productVariants) {
      productVariants.forEach(({ id: productVariantId, replaceableBy }) => {
        if (replaceableBy.length > 0) {
          const availableProductVariants = replaceableBy.map((productVariant) => productVariant.color.name);

          trackColorAvailable({
            productVariantId,
            available: availableProductVariants,
            replaceableNumber: availableProductVariants.length,
          });
        }
      });
    }
  }, [productVariants, trackColorAvailable]);
};

export { useTrackProductVariantsColorAvailable };
