import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius5, colorBgBaseTransparent, colorBgPrimary, colorTextLight, space2, space6 } = theme();

const COLORS = [colorBgBaseTransparent, colorBgPrimary];

const style = StyleSheet.create({
  button: {
    alignSelf: "flex-start",
  },
  column: {
    paddingVertical: space6,
  },
  container: {
    flex: 1,
  },
  content: {
    justifyContent: "flex-end",
    padding: space6,
  },
  description: {
    color: colorTextLight,
    marginBottom: space6,
  },
  imageContainer: {
    flex: 1,
  },
  linearGradient: {
    flex: 1,
    opacity: 0.5,
  },
  pressable: {
    aspectRatio: 4 / 5,
    borderRadius: borderRadius5,
    overflow: "hidden",
  },
  title: {
    color: colorTextLight,
    marginBottom: space2,
  },
});

export { style, COLORS };
