import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgPrimaryLight, colorBgWarning, space6 } = theme();

const style = StyleSheet.create({
  bodyRow: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    flex: "none",
    paddingBottom: space6,
  },
  content: {
    minHeight: 800,
  },
  looksContent: {
    backgroundColor: colorBgPrimaryLight,
  },
  notification: {
    backgroundColor: colorBgWarning,
  },
});

export { style };
