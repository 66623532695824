import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BoxPreviewFeedbackDto } from "../../infrastructure/persistence/boxPreviewFeedbackDto";

const VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID = "view_box_preview_feedback_by_box_preview_id";

interface ViewBoxPreviewFeedbackByBoxPreviewIdPayload {
  readonly boxPreviewId: string;
}

interface ViewBoxPreviewFeedbackByBoxPreviewId
  extends Query<typeof VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID>,
    ViewBoxPreviewFeedbackByBoxPreviewIdPayload {}

interface ViewBoxPreviewFeedbackByBoxPreviewIdFunction {
  (payload: ViewBoxPreviewFeedbackByBoxPreviewIdPayload): ViewBoxPreviewFeedbackByBoxPreviewId;
}

const viewBoxPreviewFeedbackByBoxPreviewId: ViewBoxPreviewFeedbackByBoxPreviewIdFunction = (payload) => ({
  ...query({ name: VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID }),
  ...payload,
});

type ViewBoxPreviewFeedbackByBoxPreviewIdResult = BoxPreviewFeedbackDto | null;

interface BoxPreviewFeedbackByBoxPreviewIdViewArgs extends CancelableQueryViewArgs {
  readonly boxPreviewId: string;
}

interface BoxPreviewFeedbackByBoxPreviewIdView {
  (args: BoxPreviewFeedbackByBoxPreviewIdViewArgs): Promise<ViewBoxPreviewFeedbackByBoxPreviewIdResult>;
}

interface ViewBoxPreviewFeedbackByBoxPreviewIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxPreviewFeedbackByBoxPreviewIdView;
}

const viewBoxPreviewFeedbackByBoxPreviewIdHandler: QueryHandlerFunction<
  ViewBoxPreviewFeedbackByBoxPreviewId,
  ViewBoxPreviewFeedbackByBoxPreviewIdResult,
  ViewBoxPreviewFeedbackByBoxPreviewIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxPreviewId }) =>
    view({ boxPreviewId, signal });

export type {
  ViewBoxPreviewFeedbackByBoxPreviewId,
  BoxPreviewFeedbackByBoxPreviewIdView,
  ViewBoxPreviewFeedbackByBoxPreviewIdResult,
};
export {
  VIEW_BOX_PREVIEW_FEEDBACK_BY_BOX_PREVIEW_ID,
  viewBoxPreviewFeedbackByBoxPreviewId,
  viewBoxPreviewFeedbackByBoxPreviewIdHandler,
};
