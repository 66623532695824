import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { Storage } from "@lookiero/sty-psp-storage";
import { BoxPreviewFeedback } from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedback";
import {
  BoxPreviewFeedbacksGetFunction,
  BoxPreviewFeedbacksSaveFunction,
} from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedbacks";
import { BoxPreviewFeedbackDto } from "../../../persistence/boxPreviewFeedbackDto";
import { storageBoxPreviewFeedbacksRemove } from "./storageBoxPreviewFeedbacksRemove";
import { storageBoxPreviewFeedbacksUpdate } from "./storageBoxPreviewFeedbacksUpdate";

const toDomain = (boxPreviewFeedbackDto: BoxPreviewFeedbackDto): BoxPreviewFeedback => ({
  aggregateId: boxPreviewFeedbackDto.boxPreviewId,
  chosenProductVariantIds: boxPreviewFeedbackDto.chosenProductVariantIds || [],
  comment: boxPreviewFeedbackDto.comment || "",
  replacedFor: boxPreviewFeedbackDto.replacedFor || {},
  domainEvents: [],
});

interface StorageBoxPreviewFeedbacksGetFunctionArgs extends RepositoryGetFunctionArgs {
  readonly storage: Storage<BoxPreviewFeedbackDto>;
}

interface StorageBoxPreviewFeedbacksGetFunction
  extends BoxPreviewFeedbacksGetFunction<StorageBoxPreviewFeedbacksGetFunctionArgs> {}

const getBoxPreviewFeedback: StorageBoxPreviewFeedbacksGetFunction =
  ({ storage }) =>
  async (aggregateId) => {
    const boxPreviewFeedbackDto = await storage.read(aggregateId);

    if (!boxPreviewFeedbackDto) {
      throw new Error(`BoxPreviewFeedback not found: ${aggregateId}`);
    }

    return toDomain(boxPreviewFeedbackDto);
  };

interface StorageBoxPreviewFeedbacksSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly storage: Storage<BoxPreviewFeedbackDto>;
}

interface StorageBoxPreviewFeedbacksSaveFunction
  extends BoxPreviewFeedbacksSaveFunction<StorageBoxPreviewFeedbacksSaveFunctionArgs> {}

const saveBoxPreviewFeedback: StorageBoxPreviewFeedbacksSaveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    await Promise.all([
      storageBoxPreviewFeedbacksUpdate({ storage })(aggregateRoot),
      storageBoxPreviewFeedbacksRemove({ storage })(aggregateRoot),
    ]);
  };

export type { StorageBoxPreviewFeedbacksSaveFunction };
export { getBoxPreviewFeedback, saveBoxPreviewFeedback };
