import { useCallback, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";
import { useCommand } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { updateBoxPreviewFeedback } from "../../../../domain/boxPreviewFeedback/command/updateBoxPreviewFeedback";
import { BoxPreviewWithFeedbackProjection } from "../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages, PREFIX } from "../../../ui/i18n/i18n";

interface UpdateReplaceProductVariantFunctionArgs {
  readonly productVariantId: string;
  readonly replacedFor: string;
}

interface UpdateReplaceProductVariantFunction {
  (args: UpdateReplaceProductVariantFunctionArgs): Promise<void>;
}

interface UpdateChosenProductVariantFunctionArgs {
  readonly productVariantId: string;
  readonly chosen: boolean;
}
interface UpdateChosenProductVariantFunction {
  (args: UpdateChosenProductVariantFunctionArgs): Promise<void>;
}

interface UseUpdateBoxPreviewFeedbackReturn {
  readonly updateChosenProductVariant: UpdateChosenProductVariantFunction;
  readonly updateIsCandidateProductVariants: (productVariantsId: string[]) => Promise<void>;
  readonly clearChosenProductVariants: () => Promise<void>;
  readonly updateComment: (comment: string) => Promise<void>;
  readonly updateReplaceProductVariant: UpdateReplaceProductVariantFunction;
}

interface UseUpdateBoxPreviewFeedbackFunctionArgs {
  readonly boxPreviewWithFeedback: BoxPreviewWithFeedbackProjection | undefined;
  readonly onError?: (error: unknown) => void;
}

interface UseUpdateBoxPreviewFeedbackFunction {
  (args: UseUpdateBoxPreviewFeedbackFunctionArgs): UseUpdateBoxPreviewFeedbackReturn;
}

const useUpdateBoxPreviewFeedback: UseUpdateBoxPreviewFeedbackFunction = ({ boxPreviewWithFeedback, onError }) => {
  const boxPreviewWithFeedbackRef = useRef(boxPreviewWithFeedback);
  boxPreviewWithFeedbackRef.current = boxPreviewWithFeedback;

  const [commandBus] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const logger = useLogger();
  const [createToastNotification] = useCreateToastNotification({
    contextId: MESSAGING_CONTEXT_ID,
    logger,
  });

  const updateReplaceProductVariant: UpdateReplaceProductVariantFunction = useCallback(
    async ({ productVariantId, replacedFor }) => {
      if (!boxPreviewWithFeedbackRef.current) {
        return;
      }

      const updatedReplaceFor =
        productVariantId === replacedFor
          ? Object.fromEntries(
              Object.entries(boxPreviewWithFeedbackRef.current.replacedFor).filter(([id]) => productVariantId !== id),
            )
          : { ...boxPreviewWithFeedbackRef.current.replacedFor, [productVariantId]: replacedFor };

      if (boxPreviewWithFeedbackRef.current.replacedFor[productVariantId] === updatedReplaceFor[productVariantId]) {
        return;
      }

      try {
        await commandBus(
          updateBoxPreviewFeedback({
            aggregateId: boxPreviewWithFeedbackRef.current.id,
            chosenProductVariantIds: boxPreviewWithFeedbackRef.current.productVariants
              .filter((productVariant) => productVariant.chosen)
              .map((productVariant) => productVariant.id),
            comment: boxPreviewWithFeedbackRef.current.comment,
            replacedFor: updatedReplaceFor,
          }),
        );

        createToastNotification({
          aggregateId: uuid(),
          level: NotificationLevel.SUCCESS,
          bodyI18nKey: `${PREFIX}${I18nMessages.DETAIL_PAGE_COLOR_CHANGED_NOTIFICATION}`,
        });
      } catch (error) {
        onError && onError(error);
      }
    },
    [commandBus, createToastNotification, onError],
  );

  const updateChosenProductVariant: UpdateChosenProductVariantFunction = useCallback(
    async ({ productVariantId, chosen }) => {
      if (!boxPreviewWithFeedbackRef.current) {
        return;
      }

      const chosenProductVariantIds = boxPreviewWithFeedbackRef.current.productVariants
        .filter((productVariant) => productVariant.chosen)
        .map((productVariant) => productVariant.id);

      const updatedChosenProductVariantIds = chosen
        ? [...new Set([...chosenProductVariantIds, productVariantId])]
        : chosenProductVariantIds.filter((chosenProductVariantId) => chosenProductVariantId !== productVariantId);

      try {
        return await commandBus(
          updateBoxPreviewFeedback({
            aggregateId: boxPreviewWithFeedbackRef.current.id,
            chosenProductVariantIds: updatedChosenProductVariantIds,
            comment: boxPreviewWithFeedbackRef.current.comment,
            replacedFor: boxPreviewWithFeedbackRef.current.replacedFor,
          }),
        );
      } catch (error) {
        onError && onError(error);
      }
    },
    [commandBus, onError],
  );

  const updateIsCandidateProductVariants = useCallback(
    async (productVariantsId: string[]) => {
      if (!boxPreviewWithFeedbackRef.current) {
        return;
      }

      try {
        return await commandBus(
          updateBoxPreviewFeedback({
            aggregateId: boxPreviewWithFeedbackRef.current.id,
            chosenProductVariantIds: productVariantsId,
            comment: boxPreviewWithFeedbackRef.current.comment,
            replacedFor: boxPreviewWithFeedbackRef.current.replacedFor,
          }),
        );
      } catch (error) {
        onError && onError(error);
      }
    },
    [commandBus, onError],
  );

  const clearChosenProductVariants = useCallback(async () => {
    if (!boxPreviewWithFeedbackRef.current) {
      return;
    }

    try {
      return await commandBus(
        updateBoxPreviewFeedback({
          aggregateId: boxPreviewWithFeedbackRef.current.id,
          chosenProductVariantIds: [],
          comment: boxPreviewWithFeedbackRef.current.comment,
          replacedFor: {},
        }),
      );
    } catch (error) {
      onError && onError(error);
    }
  }, [commandBus, onError]);

  const updateComment = useCallback(
    async (comment: string) => {
      if (!boxPreviewWithFeedbackRef.current) {
        return;
      }

      try {
        return await commandBus(
          updateBoxPreviewFeedback({
            aggregateId: boxPreviewWithFeedbackRef.current.id,
            chosenProductVariantIds: boxPreviewWithFeedbackRef.current.productVariants
              .filter((productVariant) => productVariant.chosen)
              .map((productVariant) => productVariant.id),
            comment,
            replacedFor: boxPreviewWithFeedbackRef.current.replacedFor,
          }),
        );
      } catch (error) {
        onError && onError(error);
      }
    },
    [commandBus, onError],
  );

  return useMemo(
    () => ({
      clearChosenProductVariants,
      updateChosenProductVariant,
      updateComment,
      updateIsCandidateProductVariants,
      updateReplaceProductVariant,
    }),
    [
      clearChosenProductVariants,
      updateChosenProductVariant,
      updateComment,
      updateIsCandidateProductVariants,
      updateReplaceProductVariant,
    ],
  );
};

export { useUpdateBoxPreviewFeedback };
