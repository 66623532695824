import React, { FC } from "react";
import { View } from "react-native";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import {
  ProductVariantOnChosenFunction,
  ProductVariantOnImageViewFunction,
  ProductVariantOnPressFunction,
} from "../productVariant/ProductVariant";
import { style } from "./OtherGarmentsProductVariants.style";
import { OtherGarmentProductVariant } from "./components/OtherGarmentsProductVariant";

interface OtherGarmentsProductVariantsProps {
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[];
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: ProductVariantOnImageViewFunction;
}
const OtherGarmentsProductVariants: FC<OtherGarmentsProductVariantsProps> = ({
  productVariants,
  onChosen,
  onPress,
  onImageView,
}) => {
  const screenSize = useScreenSize();
  const itemScreenStyle = screenSize === "S" ? style.itemSmall : style.itemDefault;

  return (
    <View style={style.container} testID="other-garments-product-variants">
      {productVariants.map((productVariant, index) => (
        <View
          key={productVariant.id}
          style={[style.item, itemScreenStyle, index % 2 === 0 ? style.childEven : style.childOdd]}
        >
          <OtherGarmentProductVariant
            productVariant={productVariant}
            onChosen={onChosen}
            onImageView={onImageView}
            onPress={onPress}
          />
        </View>
      ))}
    </View>
  );
};

export { OtherGarmentsProductVariants };
