import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space6 } = theme();

const style = StyleSheet.create({
  text: {
    marginTop: space6,
    width: "100%",
  },
});

export { style };
