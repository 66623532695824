import { registerRootComponent } from "expo";
import { enableLegacyWebImplementation } from "react-native-gesture-handler";
import { bootstrap } from "./ExpoRoot";

enableLegacyWebImplementation(true);

(async () => {
  const ExpoRoot = await bootstrap();

  registerRootComponent(ExpoRoot);
})();
