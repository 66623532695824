import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { View } from "react-native";
import {
  AspectRatioView,
  Bullets,
  Carousel,
  RenderBulletsFunction,
  RenderItemFunction,
  useScreenSize,
} from "@lookiero/sty-psp-ui";
import { BoxPreviewProductVariantProjection } from "../../../../../projection/boxPreview/boxPreview";
import { MediaProjection, noMainTransparentMedia } from "../../../../../projection/media/media";
import { useEnvironment } from "../../../../projection/environment/react/useEnvironment";
import { BoxPreviewImageViewTrackerParams } from "../../../../tracking/tracking";
import { toLookProductVariants } from "../garmentsProductVariants/components/garmentProductVariant/GarmentProductVariant";
import { LookProductVariants } from "../looksProductVariants/components/LookProductVariants";
import { ProductVariant, ProductVariantOnImageViewFunction } from "../productVariant/ProductVariant";
import { style } from "./ProductVariantGallery.style";

const isMedia = (
  question: MediaProjection | (BoxPreviewProductVariantProjection | undefined)[],
): question is MediaProjection => Boolean((question as MediaProjection).url);

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface ProductVariantGalleryProps {
  readonly productVariant: BoxPreviewProductVariantProjection;
  readonly looksProductVariants: (BoxPreviewProductVariantProjection | undefined)[][];
  readonly onImageView: OnImageViewFunction;
}
const ProductVariantGallery: FC<ProductVariantGalleryProps> = ({
  productVariant,
  looksProductVariants,
  onImageView,
}) => {
  const {
    media: {
      sizes: { detail: detailMediaSize },
    },
  } = useEnvironment();
  const screenSize = useScreenSize();
  const isDektop = screenSize === "L";

  const galleryMedia: MediaProjection[] = useMemo(
    () => noMainTransparentMedia(productVariant.media),
    [productVariant.media],
  );
  const data = useMemo(() => [...galleryMedia, ...looksProductVariants], [galleryMedia, looksProductVariants]);

  const swipeRef = useRef(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const handleOnActiveChanged = useCallback((activeIndex: number) => {
    setActiveIndex(activeIndex);
    swipeRef.current = true;
  }, []);
  useEffect(() => setActiveIndex(0), [data]);

  const handleOnImageView: ProductVariantOnImageViewFunction = useCallback(
    ({ perspective, productVariantId, look, lookProductVariantId }) => {
      onImageView?.({
        perspective,
        productVariantId,
        look,
        lookProductVariantId,
        swipe: swipeRef.current,
      });

      return true;
    },
    [onImageView],
  );

  const renderItem: RenderItemFunction<MediaProjection | (BoxPreviewProductVariantProjection | undefined)[]> =
    useCallback(
      ({ item, index }) =>
        isMedia(item) ? (
          <ProductVariant
            key={item.url}
            chosen={productVariant.chosen}
            isCandidate={productVariant.isCandidate}
            media={item}
            mediaSize={detailMediaSize}
            productVariantId={productVariant.id}
            onImageView={handleOnImageView}
          />
        ) : (
          <AspectRatioView key={index} aspectRatio={1.25}>
            <LookProductVariants
              lookProductVariants={toLookProductVariants(item)}
              mediaSize={detailMediaSize}
              onImageView={handleOnImageView}
            />
          </AspectRatioView>
        ),
      [detailMediaSize, handleOnImageView, productVariant.chosen, productVariant.id, productVariant.isCandidate],
    );

  const renderBullets: RenderBulletsFunction = useCallback(
    ({ activeIndex, count, onChange }) => <Bullets activeIndex={activeIndex} count={count} onChange={onChange} />,
    [],
  );

  return isDektop ? (
    <View style={style.gallery} testID="product-variant-gallery">
      {data.map((item, index) => (
        <View key={index} style={style.item}>
          {renderItem({ item, index })}
        </View>
      ))}
    </View>
  ) : (
    <Carousel
      activeIndex={activeIndex}
      bullets={renderBullets}
      data={data}
      style={{ container: style.carouselContainer }}
      onActiveIndexChanged={handleOnActiveChanged}
    >
      {renderItem}
    </Carousel>
  );
};

export { ProductVariantGallery };
