import debounce from "lodash.debounce";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type HandleOnLocalCommentChangedFunction = (comment: string) => void;

type UseSyncLocalCommentResult = [string, HandleOnLocalCommentChangedFunction];

interface UseSyncLocalCommentFunctionArgs {
  readonly comment: string;
  readonly onChanged?: (comment: string) => void;
}
interface UseSyncLocalCommentFunction {
  (args: UseSyncLocalCommentFunctionArgs): UseSyncLocalCommentResult;
}
const useSyncLocalComment: UseSyncLocalCommentFunction = ({ comment, onChanged }) => {
  const [localComment, setLocalComment] = useState(comment);
  const modifiedOn = useRef(0);
  const syncedOn = useRef(0);

  useEffect(() => {
    if (syncedOn.current < modifiedOn.current) {
      return;
    }

    setLocalComment(comment);
  }, [comment]);

  const syncComment = useMemo(
    () =>
      debounce((newComment: string) => {
        syncedOn.current = Date.now();
        onChanged?.(newComment);
      }, 200),
    [onChanged],
  );

  const handleOnLocalCommentChanged: HandleOnLocalCommentChangedFunction = useCallback(
    (comment: string) => {
      modifiedOn.current = Date.now();
      setLocalComment(comment);
      syncComment(comment);
    },
    [syncComment],
  );

  return [localComment, handleOnLocalCommentChanged];
};

export { useSyncLocalComment };
