import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_PREVIEW_FEEDBACK_UPDATED = "box_preview_feedback_updated";

interface BoxPreviewFeedbackUpdatedPayload {
  readonly aggregateId: string;
}

interface BoxPreviewFeedbackUpdated
  extends DomainEvent<typeof BOX_PREVIEW_FEEDBACK_UPDATED>,
    BoxPreviewFeedbackUpdatedPayload {}

interface BoxPreviewFeedbackUpdatedFunction {
  (payload: BoxPreviewFeedbackUpdatedPayload): BoxPreviewFeedbackUpdated;
}

const boxPreviewFeedbackUpdated: BoxPreviewFeedbackUpdatedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: BOX_PREVIEW_FEEDBACK_UPDATED });

const isBoxPreviewFeedbackUpdated = (event: DomainEvent<MessageName>): event is BoxPreviewFeedbackUpdated =>
  event.name === BOX_PREVIEW_FEEDBACK_UPDATED;

export type { BoxPreviewFeedbackUpdated };
export { BOX_PREVIEW_FEEDBACK_UPDATED, boxPreviewFeedbackUpdated, isBoxPreviewFeedbackUpdated };
