import { animated, useTransition } from "@react-spring/native";
import React, { FC, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { style } from "./Sticky.style";

interface StickyProps {
  readonly children: ReactNode;
  readonly visible: boolean;
  readonly testID?: string;
  readonly style?: StyleProp<ViewStyle>;
}

const Sticky: FC<StickyProps> = ({ children, visible, style: customStyle, testID }) => {
  const transitions = useTransition(visible, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    unique: true,
    trail: 150,
  });

  return transitions(
    ({ opacity }, item) =>
      item && (
        <animated.View
          pointerEvents={visible ? "auto" : "none"}
          testID={testID}
          style={[
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            style.sticky,
            {
              opacity,
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            customStyle,
          ]}
        >
          {children}
        </animated.View>
      ),
  );
};

export { Sticky };
