import React, { FC, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Text, View } from "@lookiero/aurora";
import { style } from "./Step.style";

interface StepProps {
  readonly children?: ReactNode;
  readonly index: number;
  readonly style?: StyleProp<ViewStyle>;
}
const Step: FC<StepProps> = ({ children, index, style: customStyle }) => (
  <View style={[style.step, customStyle]}>
    <View style={style.index}>
      <Text level={3} detail>
        {index}
      </Text>
    </View>

    <View style={style.text}>
      <Text level={3}>{children}</Text>
    </View>
  </View>
);

export { Step };
