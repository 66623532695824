import React, { FC, useMemo, useCallback } from "react";
import { View } from "react-native";
import { useNavigate } from "react-router";
import { Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { CommandStatus } from "@lookiero/messaging-react";
import { BoxPreviewWithFeedbackProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Description } from "../../atoms/description/Description";
import { Title } from "../../atoms/title/Title";
import { Body } from "../../layouts/body/Body";
import { Layout } from "../../layouts/layout/Layout";
import { Comment } from "../../organisms/comment/Comment";
import { SummaryProductVariants } from "../../organisms/summaryProductVariants/SummaryProductVariants";
import { Footer } from "../footer/Footer";
import { FeedbackHeader } from "../header/feedbackHeader/FeedbackHeader";
import { style } from "./Feedback.style";

const MAX_CHOSEN_PRODUCT_VARIANTS = 5;

enum FeedbackType {
  ALL_ITEMS_DISCARDED = "ALL_ITEMS_DISCARDED",
  ITEMS_SELECTED = "ITEMS_SELECTED",
  ALL_ITEMS_SELECTED = "ALL_ITEMS_SELECTED",
}

interface Text {
  readonly title: string;
  readonly description: string;
}

const FEEDBACK_EXPERIMENT_TEXT: Record<FeedbackType, Text> = {
  [FeedbackType.ALL_ITEMS_DISCARDED]: {
    title: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_DISCARDED_TITLE,
    description: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_DISCARDED_DESCRIPTION,
  },
  [FeedbackType.ITEMS_SELECTED]: {
    title: I18nMessages.SUBMIT_PAGE_ITEMS_SELECTED_TITLE,
    description: I18nMessages.SUBMIT_PAGE_ITEMS_SELECTED_DESCRIPTION,
  },
  [FeedbackType.ALL_ITEMS_SELECTED]: {
    title: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_SELECTED_TITLE,
    description: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_SELECTED_DESCRIPTION,
  },
};

interface OnSubmitFunctionArgs {
  readonly productVariantIds: string[];
  readonly comment: string;
  readonly replacedFor: Record<string, string>;
}
interface OnSubmitFunction {
  (args: OnSubmitFunctionArgs): void;
}

interface FeedbackProps {
  readonly boxPreviewWithFeedback: BoxPreviewWithFeedbackProjection;
  readonly maxCommentLength: number;
  readonly submitState?: CommandStatus;
  readonly onSubmit?: OnSubmitFunction;
  readonly onCommentChanged?: (comment: string) => void;
}
const Feedback: FC<FeedbackProps> = ({
  boxPreviewWithFeedback,
  onCommentChanged,
  maxCommentLength,
  onSubmit,
  submitState = CommandStatus.IDLE,
}) => {
  const chosenProductVariants = useMemo(
    () => boxPreviewWithFeedback.productVariants.filter((productVariant) => productVariant.chosen),
    [boxPreviewWithFeedback.productVariants],
  );

  const hasChosenProductVariants = chosenProductVariants.length > 0;
  const allItemsSelected = chosenProductVariants.length === MAX_CHOSEN_PRODUCT_VARIANTS;

  const feedbackType: FeedbackType = allItemsSelected
    ? FeedbackType.ALL_ITEMS_SELECTED
    : hasChosenProductVariants
      ? FeedbackType.ITEMS_SELECTED
      : FeedbackType.ALL_ITEMS_DISCARDED;

  const { title, description } = FEEDBACK_EXPERIMENT_TEXT[feedbackType];

  const titleText = useI18nMessage({ prefix: PREFIX, id: title });
  const descriptionText = useI18nMessage({
    prefix: PREFIX,
    id: description,
    values: { count: `${MAX_CHOSEN_PRODUCT_VARIANTS - chosenProductVariants.length}` },
  });
  const submitButtonText = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.SUBMIT_PAGE_SUBMIT_BUTTON,
    values: { count: `${chosenProductVariants.length}` },
  });

  const replacedFor: Record<string, string> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(boxPreviewWithFeedback.replacedFor).filter(([id]) =>
          chosenProductVariants.some((productVariant) => productVariant.id === id),
        ),
      ),
    [boxPreviewWithFeedback.replacedFor, chosenProductVariants],
  );

  const handleSubmit = useCallback(
    () =>
      onSubmit?.({
        productVariantIds: chosenProductVariants.map((productVariant) => productVariant.id),
        comment: boxPreviewWithFeedback.comment,
        replacedFor,
      }),
    [boxPreviewWithFeedback.comment, chosenProductVariants, onSubmit, replacedFor],
  );

  const navigate = useNavigate();
  const handleOnBack = useCallback(() => navigate(-1), [navigate]);

  return (
    <Layout header={<FeedbackHeader onBack={handleOnBack} />} stickyHeader>
      <Body style={{ row: style.bodyRow }}>
        <View style={style.titleDescriptionContainer}>
          <Title>{titleText}</Title>

          <Description>{descriptionText}</Description>
        </View>

        {hasChosenProductVariants && <SummaryProductVariants productVariants={chosenProductVariants} />}

        <Comment
          comment={boxPreviewWithFeedback.comment}
          feedbackType={feedbackType}
          maxLength={maxCommentLength}
          onChanged={onCommentChanged}
        />

        <Button busy={submitState === CommandStatus.LOADING} testID="feedback-submit-button" onPress={handleSubmit}>
          {submitButtonText}
        </Button>
      </Body>

      <Footer />
    </Layout>
  );
};

export { Feedback };
