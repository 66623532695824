import React, { FC, useState, useEffect } from "react";
import { View } from "react-native";
import Animated, { Easing, useAnimatedStyle, withTiming } from "react-native-reanimated";
import { useI18nMessage } from "@lookiero/i18n-react";
import { BoxPreviewProductVariantProjection } from "../../../../../projection/boxPreview/boxPreview";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Description } from "../../atoms/description/Description";
import { Title } from "../../atoms/title/Title";
import { Body } from "../../layouts/body/Body";
import { Layout } from "../../layouts/layout/Layout";
import { Notification } from "../../molecules/notification/Notification";
import { style } from "./Submitted.style";
import { Banner } from "./banner/Banner";
import { Footer } from "./footer/Footer";
import { DoubleHalf } from "./shapes/DoubleHalf";
import { Rectangle } from "./shapes/Rectangle";

const animationConfig = {
  duration: 1000,
  easing: Easing.inOut(Easing.cubic),
};
const SHAPE_ANIMATION_DELTA_Y = 60;

interface SubmittedProps {
  readonly chosenProductVariants: BoxPreviewProductVariantProjection[];
  readonly replacedFor: Record<string, string>;
  readonly discoveryStyleTypeformFeatureToggleEnabled: boolean;
  readonly onPressBanner: () => void;
}
const Submitted: FC<SubmittedProps> = ({
  chosenProductVariants,
  replacedFor,
  discoveryStyleTypeformFeatureToggleEnabled,
  onPressBanner,
}) => {
  const title = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.CONFIRMATION_PAGE_TITLE,
  });
  const descriptionText = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.CONFIRMATION_PAGE_DESCRIPTION,
  });
  const colorChangedText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_COLOR_CHANGED });

  const hasReplacedFor = chosenProductVariants.some((productVariant) =>
    Object.keys(replacedFor).includes(productVariant.id),
  );

  const [visible, setVisible] = useState(false);
  useEffect(() => setVisible(true), []);

  const rectangleAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(visible ? 1 : 0, animationConfig),
      transform: [{ translateY: withTiming(visible ? 0 : SHAPE_ANIMATION_DELTA_Y, animationConfig) }],
    }),
    [visible],
  );
  const doubleHalfAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: withTiming(visible ? 1 : 0, animationConfig),
      transform: [{ translateY: withTiming(visible ? 0 : -SHAPE_ANIMATION_DELTA_Y, animationConfig) }],
    }),
    [visible],
  );

  return (
    <Layout>
      {hasReplacedFor && (
        <Notification icon="info" style={style.notification} testID="submitted-color-changed-notification">
          {colorChangedText}
        </Notification>
      )}

      <View style={style.content}>
        <View style={style.shapeContainer}>
          <Animated.View style={[style.shape, style.doubleHalf, doubleHalfAnimatedStyle]}>
            <DoubleHalf height={309} width={240} />
          </Animated.View>
        </View>

        <Body style={{ column: style.column }}>
          <View style={style.text}>
            <Title style={style.title}>{title}</Title>

            <Description>{descriptionText}</Description>
          </View>
        </Body>

        <View style={style.shapeContainer}>
          <Animated.View style={[style.shape, style.rectangle, rectangleAnimatedStyle]}>
            <Rectangle height={312} width={198} />
          </Animated.View>
        </View>

        {discoveryStyleTypeformFeatureToggleEnabled && <Banner onPress={onPressBanner} />}
      </View>

      <Footer />
    </Layout>
  );
};

export { Submitted };
