import invariant from "tiny-invariant";
import { RepositoryGetFunctionArgs, RepositorySaveFunctionArgs } from "@lookiero/messaging";
import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { BoxPreview, BoxPreviewStatus } from "../../../../domain/boxPreview/model/boxPreview";
import { BoxPreviewsGetFunction, BoxPreviewsSaveFunction } from "../../../../domain/boxPreview/model/boxPreviews";
import { ViewBoxPreviewById, viewBoxPreviewById } from "../../../../projection/boxPreview/viewBoxPreviewById";
import { BoxPreviewDto } from "../../../persistence/boxPreviewDto";
import { httpBoxPreviewsSubmit } from "./httpBoxPreviewsSubmit";

type ViewBoxPreviewByIdResult = BoxPreviewDto | null;

interface ToDomainFunction {
  (boxPreview: ViewBoxPreviewByIdResult): BoxPreview | never;
}

const toDomain: ToDomainFunction = (boxPreview) => {
  invariant(boxPreview, "No boxPreview found!");

  return {
    boxId: boxPreview.box_id,
    comment: boxPreview.comment,
    expiration: new Date(boxPreview.expiration),
    productVariantIds: boxPreview.product_variants.map((productVariant) => productVariant.id),
    status: boxPreview.status as BoxPreviewStatus,
    replacedFor: boxPreview.replaced_for || {},
    aggregateId: boxPreview.id,
    domainEvents: [],
  };
};

interface HttpBoxPreviewsGetFunctionArgs extends RepositoryGetFunctionArgs {}

interface HttpBoxPreviewsGetFunction extends BoxPreviewsGetFunction<HttpBoxPreviewsGetFunctionArgs> {}

const getBoxPreview: HttpBoxPreviewsGetFunction =
  ({ queryBus }) =>
  async (aggregateId) =>
    toDomain(
      await queryBus<ViewBoxPreviewById, ViewBoxPreviewByIdResult>(viewBoxPreviewById({ boxPreviewId: aggregateId })),
    );

interface HttpBoxPreviewsSaveFunctionArgs extends RepositorySaveFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxPreviewsSaveFunction extends BoxPreviewsSaveFunction<HttpBoxPreviewsSaveFunctionArgs> {}

const saveBoxPreview: HttpBoxPreviewsSaveFunction =
  ({ httpPost }) =>
  async (aggregateRoot) => {
    await httpBoxPreviewsSubmit({ httpPost })(aggregateRoot);
  };

export type { HttpBoxPreviewsSaveFunction };
export { getBoxPreview, saveBoxPreview };
