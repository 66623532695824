import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space2 } = theme();

const style = StyleSheet.create({
  discountedPriceText: {
    marginLeft: space2,
  },
  price: {
    flexDirection: "row",
  },
  priceTextDiscounted: {
    textDecorationLine: "line-through",
  },
});

export { style };
