import React, { FC } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nNumber } from "@lookiero/i18n-react";
import { PriceProjection } from "../../../../../projection/price/price";
import { style } from "./Price.style";

interface PriceProps {
  readonly price: PriceProjection;
}
const Price: FC<PriceProps> = ({ price }) => {
  const isDiscounted = price.discountedPrice && price.discountedPrice.percentage !== 0;
  const productPrice = useI18nNumber({
    value: price.amount / 100,
    style: "currency",
    currency: price.currency,
  });
  const productDiscountedPrice = useI18nNumber({
    value: (price.discountedPrice?.amount || 0) / 100,
    style: "currency",
    currency: price.currency,
  });

  return (
    <View style={style.price} testID="price">
      <Text level={2} style={isDiscounted && style.priceTextDiscounted} testID="price-text" detail>
        {productPrice}
      </Text>

      {isDiscounted && (
        <Text level={2} style={style.discountedPriceText} testID="discounted-price-text" detail>
          {productDiscountedPrice}
        </Text>
      )}
    </View>
  );
};

export { Price };
