import React, { createContext, FC, ReactNode, useContext } from "react";
import invariant from "tiny-invariant";
import { EnvironmentProjection } from "../../../../projection/environment/environment";

const EnvironmentContext = createContext<EnvironmentProjection>(null as unknown as EnvironmentProjection);

interface EnvironmentContextProviderProps {
  readonly environment: EnvironmentProjection;
  readonly children: ReactNode;
}

const EnvironmentProvider: FC<EnvironmentContextProviderProps> = ({ environment, children }) => (
  <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>
);

const useEnvironment = (): EnvironmentProjection => {
  const environment = useContext<EnvironmentProjection>(EnvironmentContext);

  invariant(
    environment,
    "Your are trying to use the useEnvironment hook without wrapping your app with the <EnvironmentProvider>.",
  );

  return environment;
};

export { EnvironmentProvider };

export { useEnvironment };
