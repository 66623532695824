import React, { FC, useCallback, useMemo } from "react";
import { useI18nMessage } from "@lookiero/i18n-react";
import { RenderItemFunction, Tabs } from "@lookiero/sty-psp-ui";
import {
  BoxPreviewWithFeedbackProductVariantProjection,
  BoxPreviewWithFeedbackProjection,
} from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { mainTransparentImageFromMedia } from "../../../../../projection/media/media";
import { BoxPreviewImageViewTrackerParams, TrackingPage } from "../../../../tracking/tracking";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { GarmentsProductVariants } from "../../organisms/garmentsProductVariants/GarmentsProductVariants";
import { LooksProductVariants } from "../../organisms/looksProductVariants/LooksProductVariants";
import {
  ProductVariantOnPressFunction,
  ProductVariantOnChosenFunction,
} from "../../organisms/productVariant/ProductVariant";
import { style } from "./ItemsTabs.style";

interface OnChosenFunctionArgs {
  readonly page: TrackingPage;
  readonly productVariantId: string;
  readonly chosen: boolean;
  readonly look?: string[];
  readonly isCandidate: boolean;
}
interface OnChosenFunction {
  (args: OnChosenFunctionArgs): void;
}

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface ItemsTabsProps {
  readonly tabIndex: number;
  readonly boxPreviewWithFeedback: BoxPreviewWithFeedbackProjection;
  readonly onTabChanged: (active: number) => void;
  readonly onChosen?: OnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: OnImageViewFunction;
}
const ItemsTabs: FC<ItemsTabsProps> = ({
  tabIndex,
  boxPreviewWithFeedback,
  onTabChanged,
  onChosen,
  onPress,
  onImageView,
}) => {
  const garmentsTab = useI18nMessage({ prefix: PREFIX, id: I18nMessages.LANDING_PAGE_GARMENTS_TAB });
  const looksTab = useI18nMessage({ prefix: PREFIX, id: I18nMessages.LANDING_PAGE_LOOKS_TAB });

  const looksProductVariants: BoxPreviewWithFeedbackProductVariantProjection[] = useMemo(
    () =>
      boxPreviewWithFeedback.productVariants.map((productVariant) => ({
        ...productVariant,
        media: [mainTransparentImageFromMedia(productVariant.replacedFor?.media || productVariant.media)],
      })),
    [boxPreviewWithFeedback.productVariants],
  );

  const garmentsProductVariants: BoxPreviewWithFeedbackProductVariantProjection[] = useMemo(
    () =>
      boxPreviewWithFeedback.productVariants.map((productVariant) => ({
        ...productVariant,
        media: productVariant.replacedFor?.media || productVariant.media,
      })),
    [boxPreviewWithFeedback.productVariants],
  );

  const handleOnChosen: ProductVariantOnChosenFunction = useCallback(
    ({ chosen, isCandidate, productVariantId }) => {
      onChosen?.({
        page: tabIndex === 0 ? TrackingPage.LOOKS : TrackingPage.ITEMS,
        productVariantId,
        chosen,
        isCandidate,
      });
    },
    [onChosen, tabIndex],
  );

  const handleOnImageView: OnImageViewFunction = useCallback(
    ({ perspective, productVariantId, look, lookProductVariantId, swipe }) =>
      onImageView?.({
        page: tabIndex === 0 ? TrackingPage.LOOKS : TrackingPage.ITEMS,
        productVariantId,
        perspective,
        look,
        lookProductVariantId,
        swipe,
      }),
    [onImageView, tabIndex],
  );

  const data = useMemo(() => [0, 0], []);
  const renderItem: RenderItemFunction<number> = useCallback(
    ({ index }) =>
      index === 0 ? (
        <LooksProductVariants
          looks={boxPreviewWithFeedback.looks}
          productVariants={looksProductVariants}
          onChosen={handleOnChosen}
          onImageView={handleOnImageView}
          onPress={onPress}
        />
      ) : (
        <GarmentsProductVariants
          looks={boxPreviewWithFeedback.looks}
          productVariants={garmentsProductVariants}
          onChosen={handleOnChosen}
          onImageView={handleOnImageView}
          onPress={onPress}
        />
      ),
    [
      boxPreviewWithFeedback.looks,
      garmentsProductVariants,
      handleOnChosen,
      handleOnImageView,
      looksProductVariants,
      onPress,
    ],
  );

  return (
    <Tabs
      data={data}
      defaultIndex={tabIndex}
      style={{ container: style.container }}
      tabLabels={[looksTab, garmentsTab]}
      onChanged={onTabChanged}
    >
      {renderItem}
    </Tabs>
  );
};

export { ItemsTabs };
