import {
  CancelableQueryViewArgs,
  query,
  Query,
  QueryHandlerFunction,
  QueryHandlerFunctionArgs,
} from "@lookiero/messaging";
import { BoxPreviewProjection } from "./boxPreview";

const VIEW_BOX_PREVIEW_BY_ID = "view_box_preview_by_id";

interface ViewBoxPreviewByIdPayload {
  readonly boxPreviewId: string;
}

interface ViewBoxPreviewById extends Query<typeof VIEW_BOX_PREVIEW_BY_ID>, ViewBoxPreviewByIdPayload {}

interface ViewBoxPreviewByIdFunction {
  (payload: ViewBoxPreviewByIdPayload): ViewBoxPreviewById;
}

const viewBoxPreviewById: ViewBoxPreviewByIdFunction = (payload) => ({
  ...query({ name: VIEW_BOX_PREVIEW_BY_ID }),
  ...payload,
});

type ViewBoxPreviewByIdResult = BoxPreviewProjection | null;

interface BoxPreviewByIdViewArgs extends CancelableQueryViewArgs {
  readonly boxPreviewId: string;
}

interface BoxPreviewByIdView {
  (args: BoxPreviewByIdViewArgs): Promise<ViewBoxPreviewByIdResult>;
}

interface ViewBoxPreviewByIdHandlerFunctionArgs extends QueryHandlerFunctionArgs {
  readonly view: BoxPreviewByIdView;
}

const viewBoxPreviewByIdHandler: QueryHandlerFunction<
  ViewBoxPreviewById,
  ViewBoxPreviewByIdResult,
  ViewBoxPreviewByIdHandlerFunctionArgs
> =
  ({ view, signal }) =>
  async ({ boxPreviewId }) =>
    view({ boxPreviewId, signal });

export type { ViewBoxPreviewById, BoxPreviewByIdView, ViewBoxPreviewByIdResult };
export { VIEW_BOX_PREVIEW_BY_ID, viewBoxPreviewById, viewBoxPreviewByIdHandler };
