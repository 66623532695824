import { domainEvent, DomainEvent } from "@lookiero/messaging";

const BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED = "box_preview_feedback_max_chosen_products_reached";

interface BoxPreviewFeedbackMaxChosenProductsReachedPayload {
  readonly aggregateId: string;
}

interface BoxPreviewFeedbackMaxChosenProductsReached
  extends DomainEvent<typeof BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED>,
    BoxPreviewFeedbackMaxChosenProductsReachedPayload {}

interface BoxPreviewFeedbackMaxChosenProductsReachedFunction {
  (payload: BoxPreviewFeedbackMaxChosenProductsReachedPayload): BoxPreviewFeedbackMaxChosenProductsReached;
}

const boxPreviewFeedbackMaxChosenProductsReached: BoxPreviewFeedbackMaxChosenProductsReachedFunction = ({
  aggregateId,
}) => domainEvent({ aggregateId, name: BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED });

export type { BoxPreviewFeedbackMaxChosenProductsReached };
export { BOX_PREVIEW_FEEDBACK_MAX_CHOSEN_PRODUCTS_REACHED, boxPreviewFeedbackMaxChosenProductsReached };
