import React, { FC } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { ButtonIcon, COLOR } from "@lookiero/aurora";
import { ColumnSize, ScreenSize, useScreenSize } from "@lookiero/sty-psp-ui";
import { Sticky } from "../../layouts/sticky/Sticky";
import { style } from "./BackToTopButton.style";

interface BackToTopButtonProps {
  readonly visible: boolean;
  readonly size?: Partial<Record<ScreenSize, ColumnSize>>;
  readonly style?: StyleProp<ViewStyle>;
  readonly onPress: () => void;
}
const BackToTopButton: FC<BackToTopButtonProps> = ({
  visible,
  size = { S: "2/3", M: "2/3", L: "1/3" },
  style: customStyle,
  onPress,
}) => {
  const screenSize = useScreenSize();
  const columnSizeForScreenSize = size[screenSize];

  return (
    <Sticky
      style={[style.sticky, customStyle, columnSizeForScreenSize && style[columnSizeForScreenSize]]}
      visible={visible}
    >
      <ButtonIcon
        color={COLOR.BG_BASE}
        name="arrow_up"
        style={style.backToTopButton}
        testID="back-to-top-button"
        onPress={onPress}
      />
    </Sticky>
  );
};

export { BackToTopButton };
