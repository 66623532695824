import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space3, space12 } = theme();

const style = StyleSheet.create({
  bodyColumn: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: space3,
    width: "100%",
  },
  button: {
    height: space12,
  },
  content: {
    flex: 1,
  },
});

export { style };
