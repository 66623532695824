import React, { FC, ReactNode, RefObject } from "react";
import {
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";
import { GestureHandlerRootView, ScrollView as RNGHScrollView } from "react-native-gesture-handler";
import { DefaultHeader } from "../../templates/header/defaultHeader/DefaultHeader";
import { Sticky } from "../sticky/Sticky";
import { style } from "./Layout.style";

interface LayoutStyle {
  readonly container: StyleProp<ViewStyle>;
  readonly scrollView: StyleProp<ViewStyle>;
}

interface LayoutProps {
  readonly children: ReactNode;
  readonly header?: JSX.Element | null;
  readonly footer?: JSX.Element | null;
  readonly footerVisible?: boolean;
  readonly scrollRef?: RefObject<RNGHScrollView>;
  readonly scrollEnabled?: boolean;
  readonly stickyHeader?: boolean;
  readonly style?: Partial<LayoutStyle>;
  readonly onLayout?: (event: LayoutChangeEvent) => void;
  readonly onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

const Layout: FC<LayoutProps> = ({
  children,
  header = <DefaultHeader />,
  stickyHeader = false,
  footer,
  footerVisible = true,
  scrollRef,
  style: customStyle,
  scrollEnabled,
  onLayout,
  onScroll,
}) => (
  <GestureHandlerRootView style={[style.container, customStyle?.container]} testID="layout">
    <RNGHScrollView
      ref={scrollRef}
      contentContainerStyle={[StyleSheet.absoluteFill, style.scrollView, customStyle?.scrollView]}
      scrollEnabled={scrollEnabled}
      scrollEventThrottle={16}
      showsVerticalScrollIndicator={false}
      stickyHeaderIndices={stickyHeader ? [0] : undefined}
      testID="layout-scroll"
      onScroll={onScroll}
    >
      {header}

      <View style={style.children} onLayout={onLayout}>
        {children}

        {footer && (
          <Sticky style={style.sticky} visible={footerVisible}>
            {footer}
          </Sticky>
        )}
      </View>
    </RNGHScrollView>
  </GestureHandlerRootView>
);

export { Layout };
