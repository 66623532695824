import { command, Command } from "@lookiero/messaging";

const SUBMIT_BOX_PREVIEW = "submit_box_preview";

interface SubmitBoxPreviewPayload {
  readonly aggregateId: string;
  readonly productVariantIds: string[];
  readonly comment: string;
  readonly currentDate: Date;
  readonly replacedFor: Record<string, string>;
}

interface SubmitBoxPreview extends Command<typeof SUBMIT_BOX_PREVIEW>, SubmitBoxPreviewPayload {}

interface SubmitBoxPreviewFunction {
  (payload: SubmitBoxPreviewPayload): SubmitBoxPreview;
}

const submitBoxPreview: SubmitBoxPreviewFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: SUBMIT_BOX_PREVIEW }),
  ...payload,
});

export type { SubmitBoxPreview };
export { SUBMIT_BOX_PREVIEW, submitBoxPreview };
