import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4, space6 } = theme();

const style = StyleSheet.create({
  childEven: {
    paddingLeft: 1,
  },
  childOdd: {
    paddingRight: 1,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
  },
  item: {
    width: "50%",
  },
  itemDefault: {
    paddingBottom: space6,
    paddingLeft: space6 / 2,
    paddingRight: space6 / 2,
  },
  itemSmall: {
    paddingBottom: space4,
    paddingLeft: space4 / 2,
    paddingRight: space4 / 2,
  },
});

export { style };
