import React, { createContext, FC, ReactNode, useContext, useMemo } from "react";
import invariant from "tiny-invariant";
import { Country } from "@lookiero/sty-psp-locale";
import { FeatureToggles } from "../../../projection/environment/environment";

type FeatureToggleKey = keyof FeatureToggles;

const FeatureToggleContext = createContext<FeatureToggles | null>(null);

type FeatureToggleContextProviderProps = {
  readonly featureToggles: FeatureToggles;
  readonly children: ReactNode;
};

const FeatureToggleProvider: FC<FeatureToggleContextProviderProps> = ({
  featureToggles,
  children,
}: FeatureToggleContextProviderProps) => (
  <FeatureToggleContext.Provider value={featureToggles}>{children}</FeatureToggleContext.Provider>
);

interface UseFeatureToggleFunctionArgs {
  readonly featureToggleKey: FeatureToggleKey;
}
interface UseFeatureToggleFunction {
  (args: UseFeatureToggleFunctionArgs): FeatureToggles[keyof FeatureToggles];
}
const useFeatureToggle: UseFeatureToggleFunction = ({ featureToggleKey }) => {
  const featureToggles = useContext<FeatureToggles | null>(FeatureToggleContext);

  invariant(
    featureToggles,
    "Your are trying to use the useFeatureToggle hook without wrapping your app with the <FeatureToggleProvider>.",
  );

  const featureToggle = featureToggles[featureToggleKey];

  invariant(featureToggle, `There is no FeatureToggle defined for "${featureToggle}".`);

  return featureToggle;
};

interface UseIsFeatureToggleEnabledByCountryFunctionArgs {
  readonly featureToggleKey: FeatureToggleKey;
  readonly country: Country | undefined;
}
interface UseIsFeatureToggleEnabledByCountryFunction {
  (args: UseIsFeatureToggleEnabledByCountryFunctionArgs): boolean;
}
const useIsFeatureToggleEnabledByCountry: UseIsFeatureToggleEnabledByCountryFunction = ({
  featureToggleKey,
  country,
}) => {
  const featureToggle = useFeatureToggle({ featureToggleKey });

  const enabled = useMemo(() => {
    if (!country) {
      return false;
    }

    return featureToggle.country.includes(country);
  }, [country, featureToggle.country]);

  return enabled;
};

export { FeatureToggleProvider, useFeatureToggle, useIsFeatureToggleEnabledByCountry };
