import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface ColorChangedFunctionArgs {
  readonly productVariantId: string;
  readonly replacedFor: string;
}

interface ColorChangedFunction {
  (args: ColorChangedFunctionArgs): void;
}

interface UseTrackColorChangedFunctionArgs {
  readonly country: Country | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseTrackColorChangedFunction {
  (args: UseTrackColorChangedFunctionArgs): ColorChangedFunction;
}

const useTrackColorChanged: UseTrackColorChangedFunction = ({ country, page, boxPreviewId }) => {
  const tracker = useTracker();

  const colorChanged: ColorChangedFunction = useCallback(
    ({ productVariantId, replacedFor }) => {
      if (!(tracker && country && page && boxPreviewId)) {
        return;
      }

      tracker.colorChanged({ country, page, boxPreviewId, productVariantId, replacedFor });
    },
    [boxPreviewId, country, page, tracker],
  );

  return colorChanged;
};

export { useTrackColorChanged };
