import { ProcessManagerFunction } from "@lookiero/messaging";
import { createToastNotification, NotificationLevel } from "@lookiero/sty-psp-notifications";
import { I18nMessages, PREFIX } from "../../../infrastructure/ui/i18n/i18n";
import { BoxPreviewFeedbackMaxChosenProductsReached } from "../model/boxPreviewFeedbackMaxChosenProductsReached";

interface CreateNotificationWhenBoxPreviewFeedbackMaxChosenProductsReachedFunction
  extends ProcessManagerFunction<BoxPreviewFeedbackMaxChosenProductsReached> {}

const createNotificationWhenBoxPreviewFeedbackMaxChosenProductsReached: CreateNotificationWhenBoxPreviewFeedbackMaxChosenProductsReachedFunction =

    ({ commandBus }) =>
    async ({ aggregateId }) => {
      await commandBus(
        createToastNotification({
          aggregateId: `BoxPreviewFeedbackMaxChosenProductsReached-${aggregateId}`,
          level: NotificationLevel.ERROR,
          bodyI18nKey: `${PREFIX}${I18nMessages.SUBMIT_PAGE_NOTIFICATION_MAX_CHOSEN_PRODUCTS}`,
        }),
      );
    };

export { createNotificationWhenBoxPreviewFeedbackMaxChosenProductsReached };
