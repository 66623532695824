import React, { FC, createContext, useContext, useCallback, ReactNode } from "react";
import invariant from "tiny-invariant";
import { CommandStatus, useCommand } from "@lookiero/messaging-react";
import { useLogger } from "@lookiero/sty-psp-logging";
import { NotificationLevel, useCreateToastNotification } from "@lookiero/sty-psp-notifications";
import { submitBoxPreview } from "../../../../domain/boxPreview/command/submitBoxPreview";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { I18nMessages, PREFIX } from "../../../ui/i18n/i18n";

interface SubmitFunctionArgs {
  readonly productVariantIds: string[];
  readonly comment: string;
  readonly replacedFor: Record<string, string>;
}

interface SubmitFunction {
  (args: SubmitFunctionArgs): Promise<void>;
}

interface BoxPreviewSubmitContextValue {
  readonly status: CommandStatus;
  readonly submit: SubmitFunction;
}
const BoxPreviewSubmitContext = createContext<BoxPreviewSubmitContextValue>(
  null as unknown as BoxPreviewSubmitContextValue,
);

interface BoxPreviewSubmitProviderProps {
  readonly boxPreviewId: string;
  readonly children: ReactNode;
}
const BoxPreviewSubmitProvider: FC<BoxPreviewSubmitProviderProps> = ({ boxPreviewId, children }) => {
  const [commandBus, status] = useCommand({ contextId: MESSAGING_CONTEXT_ID });

  const logger = useLogger();
  const [createToastNotification] = useCreateToastNotification({
    contextId: MESSAGING_CONTEXT_ID,
    logger,
  });

  const submit: SubmitFunction = useCallback(
    async ({ productVariantIds, comment, replacedFor }) => {
      try {
        return await commandBus(
          submitBoxPreview({
            aggregateId: boxPreviewId,
            productVariantIds,
            comment,
            currentDate: new Date(),
            replacedFor,
          }),
        );
      } catch (error) {
        createToastNotification({
          level: NotificationLevel.ERROR,
          bodyI18nKey: `${PREFIX}${I18nMessages.SUBMIT_PAGE_NOTIFICATION_SUBMIT_FAILED_BODY}`,
        });
      }
    },
    [boxPreviewId, commandBus, createToastNotification],
  );

  return <BoxPreviewSubmitContext.Provider value={{ status, submit }}>{children}</BoxPreviewSubmitContext.Provider>;
};

interface UseSubmitBoxPreviewFunction {
  (): BoxPreviewSubmitContextValue;
}
const useSubmitBoxPreview: UseSubmitBoxPreviewFunction = () => {
  const submitContext = useContext<BoxPreviewSubmitContextValue>(BoxPreviewSubmitContext);

  invariant(
    submitContext,
    "Your are trying to use the useSubmitBoxPreview hook without wrapping your app with the <BoxPreviewSubmitProvider>.",
  );

  return submitContext;
};

export { useSubmitBoxPreview, BoxPreviewSubmitProvider };
