import React, { FC, useCallback } from "react";
import { FlatList } from "react-native";
import { LooksProjection } from "../../../../../projection/boxPreview/boxPreview";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { BoxPreviewImageViewTrackerParams } from "../../../../tracking/tracking";
import { Body } from "../../layouts/body/Body";
import { ProductVariantOnChosenFunction, ProductVariantOnPressFunction } from "../productVariant/ProductVariant";
import { style } from "./GarmentsProductVariants.style";
import { GarmentsProductVariant } from "./components/garmentsProductVariant/GarmentsProductVariant";

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface GarmentsProductVariantsProps {
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[];
  readonly looks: LooksProjection | undefined;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: OnImageViewFunction;
}
const GarmentsProductVariants: FC<GarmentsProductVariantsProps> = ({
  productVariants,
  looks,
  onChosen,
  onPress,
  onImageView,
}) => {
  const findProductVariantById = useCallback(
    (id: string) => productVariants.find((productVariant) => productVariant.id === id),
    [productVariants],
  );

  const looksProductVariants = useCallback(
    (productVariantId: string) =>
      looks?.filter((look) => look.includes(productVariantId)).map((look) => look.map(findProductVariantById)) || [],
    [findProductVariantById, looks],
  );

  const renderItem = useCallback(
    ({ item }: { item: BoxPreviewWithFeedbackProductVariantProjection }) => (
      <GarmentsProductVariant
        looksProductVariants={looksProductVariants(item.id)}
        productVariant={item}
        onChosen={onChosen}
        onImageView={onImageView}
        onPress={onPress}
      />
    ),
    [looksProductVariants, onChosen, onImageView, onPress],
  );

  return (
    <Body style={{ row: style.bodyRow }}>
      <FlatList
        columnWrapperStyle={style.columnWrapper}
        contentContainerStyle={style.contentContainer}
        data={productVariants}
        keyExtractor={(item) => item.id}
        numColumns={2}
        renderItem={renderItem}
        testID="garments-product-variants"
      />
    </Body>
  );
};

export { GarmentsProductVariants };
