import React, { FC } from "react";
import { Header as AuroraHeader } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../../i18n/i18n";

interface FeedbackHeaderProps {
  readonly onBack: () => void;
}
const FeedbackHeader: FC<FeedbackHeaderProps> = ({ onBack }) => {
  const headerTitle = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_HEADER_TITLE });

  return <AuroraHeader title={headerTitle} onBack={onBack} />;
};

export { FeedbackHeader };
