import { ProcessManagerFunction } from "@lookiero/messaging";
import { NotificationLevel, createToastNotification } from "@lookiero/sty-psp-notifications";
import { I18nMessages, PREFIX } from "../../../infrastructure/ui/i18n/i18n";
import { BoxPreviewMaxChosenProductsReached } from "../model/boxPreviewMaxChosenProductsReached";

interface CreateNotificationWhenBoxPreviewMaxChosenProductsReachedFunction
  extends ProcessManagerFunction<BoxPreviewMaxChosenProductsReached> {}

const createNotificationWhenBoxPreviewMaxChosenProductsReached: CreateNotificationWhenBoxPreviewMaxChosenProductsReachedFunction =

    ({ commandBus }) =>
    async ({ aggregateId }) => {
      await commandBus(
        createToastNotification({
          aggregateId: `BoxPreviewMaxChosenProductsReached-${aggregateId}`,
          level: NotificationLevel.ERROR,
          bodyI18nKey: `${PREFIX}${I18nMessages.SUBMIT_PAGE_NOTIFICATION_MAX_CHOSEN_PRODUCTS}`,
        }),
      );
    };

export { createNotificationWhenBoxPreviewMaxChosenProductsReached };
