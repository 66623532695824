import { CommandHandlerFunction } from "@lookiero/messaging";
import { AggregateRoot } from "@lookiero/messaging";
import { RemoveBoxPreviewFeedback } from "../command/removeBoxPreviewFeedback";
import { UpdateBoxPreviewFeedback } from "../command/updateBoxPreviewFeedback";
import { boxPreviewFeedbackMaxChosenProductsReached } from "./boxPreviewFeedbackMaxChosenProductsReached";
import { boxPreviewFeedbackRemoved } from "./boxPreviewFeedbackRemoved";
import { boxPreviewFeedbackUpdated } from "./boxPreviewFeedbackUpdated";

const MAX_CHOSEN_PRODUCT_VARIANTS = 5;

interface BoxPreviewFeedback extends AggregateRoot {
  readonly chosenProductVariantIds: string[] | undefined;
  readonly comment: string | undefined;
  readonly replacedFor: Record<string, string> | undefined;
}

const removeBoxPreviewFeedbackHandler: CommandHandlerFunction<RemoveBoxPreviewFeedback, BoxPreviewFeedback> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId } = command;

    return {
      ...aggregateRoot,
      domainEvents: [boxPreviewFeedbackRemoved({ aggregateId })],
    };
  };

const updateBoxPreviewFeedbackHandler: CommandHandlerFunction<UpdateBoxPreviewFeedback, BoxPreviewFeedback> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, chosenProductVariantIds, comment, replacedFor } = command;

    if (chosenProductVariantIds.length > MAX_CHOSEN_PRODUCT_VARIANTS) {
      return {
        ...aggregateRoot,
        domainEvents: [boxPreviewFeedbackMaxChosenProductsReached({ aggregateId })],
      };
    }

    return {
      ...aggregateRoot,
      chosenProductVariantIds,
      comment,
      replacedFor,
      domainEvents: [boxPreviewFeedbackUpdated({ aggregateId })],
    };
  };

export type { BoxPreviewFeedback };
export { removeBoxPreviewFeedbackHandler, updateBoxPreviewFeedbackHandler };
