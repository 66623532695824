import React, { FC } from "react";
import { View } from "react-native";
import { COLOR, Input } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useSyncLocalComment } from "../../../hooks/useSyncLocalComment";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Description } from "../../atoms/description/Description";
import { style } from "./Comment.style";

const PLACEHOLDER_TEXT_COLOR = "#837C7C";

enum FeedbackType {
  ALL_ITEMS_DISCARDED = "ALL_ITEMS_DISCARDED",
  ITEMS_SELECTED = "ITEMS_SELECTED",
  ALL_ITEMS_SELECTED = "ALL_ITEMS_SELECTED",
}

interface Text {
  readonly description: string;
  readonly placeholder: string;
}

const COMMENT_EXPERIMENT_TEXT: Record<FeedbackType, Text> = {
  [FeedbackType.ALL_ITEMS_DISCARDED]: {
    description: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_DISCARDED_COMMENTS_DESCRIPTION,
    placeholder: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_DISCARDED_COMMENTS_PLACEHOLDER,
  },
  [FeedbackType.ITEMS_SELECTED]: {
    description: I18nMessages.SUBMIT_PAGE_ITEMS_SELECTED_COMMENTS,
    placeholder: I18nMessages.SUBMIT_PAGE_ITEMS_SELECTED_COMMENTS_PLACEHOLDER,
  },
  [FeedbackType.ALL_ITEMS_SELECTED]: {
    description: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_SELECTED_COMMENTS,
    placeholder: I18nMessages.SUBMIT_PAGE_ALL_ITEMS_SELECTED_COMMENTS_PLACEHOLDER,
  },
};

interface CommentProps {
  readonly comment: string;
  readonly maxLength: number;
  readonly feedbackType: FeedbackType;
  readonly onChanged?: (comment: string) => void;
}
const Comment: FC<CommentProps> = ({ comment, maxLength, feedbackType, onChanged }) => {
  const { description, placeholder } = COMMENT_EXPERIMENT_TEXT[feedbackType];

  const descriptionText = useI18nMessage({ prefix: PREFIX, id: description });
  const placeholderText = useI18nMessage({ prefix: PREFIX, id: placeholder });

  const [localComment, setLocalComment] = useSyncLocalComment({ comment, onChanged });

  return (
    <View style={style.container} testID="comment">
      <Description color={COLOR.TEXT} testID={description}>
        {descriptionText}
      </Description>

      <View style={style.inputContainer}>
        <Input
          autoCapitalize="sentences"
          maxLength={maxLength}
          placeholder={placeholderText}
          placeholderColor={PLACEHOLDER_TEXT_COLOR}
          testID="comment-input"
          value={localComment}
          multiline
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onChange={setLocalComment}
        />
      </View>
    </View>
  );
};

export { Comment };
