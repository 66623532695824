import React, { ComponentType, useCallback } from "react";
import { Platform } from "react-native";
import { I18n } from "@lookiero/i18n-react";
// TODO properly export MessagingRoot from @lookiero/messaging-react
import { MessagingRoot } from "@lookiero/messaging-react/bootstrap";
import { SentryEnvironment, SentryLoggerFunctionArgs, sentryLogger, sentryLoggerHOC } from "@lookiero/sty-psp-logging";
import { Routing } from "./routing/Routing";

interface RootFunctionArgs {
  readonly Messaging: MessagingRoot;
  readonly I18n: I18n;
  readonly sentry: () => SentryEnvironment;
}

interface RootFunction {
  (args: RootFunctionArgs): (args: SentryLoggerFunctionArgs) => ComponentType;
}

const root: RootFunction = ({ Messaging, I18n, sentry }) => {
  const logger = sentryLogger(sentry);

  const Root = () => {
    const handleOnI18nError = useCallback((error: Error) => logger.captureException(error), []);

    return (
      <Messaging includeReactQueryDevTools={Platform.OS === "web"}>
        <Routing I18n={I18n} onI18nError={handleOnI18nError} />
      </Messaging>
    );
  };

  const hoc = sentryLoggerHOC({ logger });

  /**
   * It's important to not call sentryLoggerHOC each time this function gets called.
   * That's why its called outside this method.
   */
  return ({ customerId }) => hoc({ customerId })(Root);
};

export { root };
