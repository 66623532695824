import { command, Command } from "@lookiero/messaging";

const UPDATE_BOX_PREVIEW_FEEDBACK = "update_box_preview_feedback";

interface UpdateBoxPreviewFeedbackPayload {
  readonly aggregateId: string;
  readonly chosenProductVariantIds: string[];
  readonly comment: string;
  readonly replacedFor: Record<string, string>;
}

interface UpdateBoxPreviewFeedback
  extends Command<typeof UPDATE_BOX_PREVIEW_FEEDBACK>,
    UpdateBoxPreviewFeedbackPayload {}

interface UpdateBoxPreviewFeedbackFunction {
  (payload: UpdateBoxPreviewFeedbackPayload): UpdateBoxPreviewFeedback;
}

const updateBoxPreviewFeedback: UpdateBoxPreviewFeedbackFunction = ({ aggregateId, ...payload }) => ({
  ...command({ aggregateId, name: UPDATE_BOX_PREVIEW_FEEDBACK }),
  ...payload,
});

export type { UpdateBoxPreviewFeedback };
export { UPDATE_BOX_PREVIEW_FEEDBACK, updateBoxPreviewFeedback };
