import React, { FC } from "react";
import { Navigate, generatePath, useMatch, useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { CommandStatus } from "@lookiero/messaging-react";
import { BoxPreviewStatus } from "../../../domain/boxPreview/model/boxPreview";
import { useSubmitBoxPreview } from "../../domain/boxPreview/react/useSubmitBoxPreview";
import { useViewBoxPreviewById } from "../../projection/boxPreview/react/useViewBoxPreviewById";
import { Loading as SubmitLoading } from "../components/templates/loading/Loading";
import { NotFound } from "../views/notFound/NotFound";
import { Routes, Slugs } from "./routes";

interface BoxPreviewMiddlewareProps {
  readonly children: JSX.Element;
}

const BoxPreviewMiddleware: FC<BoxPreviewMiddlewareProps> = ({ children }) => {
  const { id } = useParams();
  const [boxPreview] = useViewBoxPreviewById({ boxPreviewId: id as string });
  const { status: submitBoxpreviewStatus } = useSubmitBoxPreview();

  const isExpiredRoute = Boolean(useMatch(Routes.EXPIRED));
  const isSubmittedRoute = Boolean(useMatch(Routes.SUBMITTED));
  const isNotifiedRoute = Boolean(useMatch(`${Routes.ITEMS}/*`));
  const isFeedbackRoute = Boolean(useMatch(Routes.FEEDBACK));

  if (
    submitBoxpreviewStatus === CommandStatus.LOADING ||
    (submitBoxpreviewStatus === CommandStatus.SUCCESS && boxPreview?.status !== BoxPreviewStatus.SUBMITTED)
  ) {
    return <SubmitLoading />;
  }

  if (!boxPreview) {
    return <Spinner />;
  }

  if (!boxPreview) {
    return <NotFound />;
  }

  if (!isExpiredRoute && boxPreview.status === BoxPreviewStatus.EXPIRED) {
    return <Navigate to={{ pathname: generatePath(Slugs.EXPIRED) }} replace />;
  }

  if (!isSubmittedRoute && boxPreview.status === BoxPreviewStatus.SUBMITTED) {
    return <Navigate to={{ pathname: generatePath(Slugs.SUBMITTED) }} replace />;
  }

  if (!(isNotifiedRoute || isFeedbackRoute) && boxPreview.status === BoxPreviewStatus.NOTIFIED) {
    return <Navigate to={{ pathname: generatePath(Slugs.ITEMS) }} replace />;
  }

  return children;
};

export { BoxPreviewMiddleware };
