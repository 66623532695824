import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius3, borderWidth1, colorBorderInteractive, space4, space6 } = theme();

const style = StyleSheet.create({
  container: {
    paddingVertical: space6,
  },
  inputContainer: {
    borderColor: colorBorderInteractive,
    borderRadius: borderRadius3,
    borderWidth: borderWidth1,
    marginTop: space4,
  },
});

export { style };
