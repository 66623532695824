const PREFIX = "box_preview.";
const CATALOG_PREFIX = "catalog.";
const CATALOG_COLOR_PREFIX = `${CATALOG_PREFIX}color.`;
const CATALOG_FEATURE_PREFIX = `${CATALOG_PREFIX}feature.`;
const CATALOG_FEATURE_VALUE_PREFIX = `${CATALOG_PREFIX}feature_value.`;

enum I18nMessages {
  NOT_FOUND_PAGE_TITLE = "not_found_page.title",
  NOT_FOUND_PAGE_DESCRIPTION = "not_found_page.description",
  LANDING_PAGE_TITLE = "landing_page.title",
  LANDING_PAGE_DESCRIPTION = "landing_page.description",
  LANDING_PAGE_TIMEFRAME_ALERT = "landing_page.timeframe_alert",
  LANDING_PAGE_DISCARD_BUTTON = "landing_page.discard_button",
  LANDING_PAGE_DISCARD_EXPLANATION = "landing_page.discard_explanation",
  LANDING_PAGE_SELECT_BUTTON = "landing_page.select_button",
  LANDING_PAGE_DESCRIPTION_SELECT_PIECES = "landing_page.description_select_pieces",
  LANDING_PAGE_DESCRIPTION_SELECT_PIECES_COUNT = "landing_page.description_select_pieces_count",
  LANDING_PAGE_TOOLTIP_TITLE = "landing_page.tooltip_title",
  LANDING_PAGE_TOOLTIP_DESCRIPTION = "landing_page.tooltip_description",
  LANDING_PAGE_GARMENTS_TAB = "landing_page.tab_garments",
  LANDING_PAGE_LOOKS_TAB = "landing_page.tab_looks",
  LANDING_PAGE_MORE_COLORS = "landing_page.more_colors",
  LOOKS_BANNER_TITLE = "looks_banner.title",
  LOOKS_BANNER_BUTTON = "looks_banner.button",
  DETAIL_PAGE_COLOR_BUTTON = "detail_page.color.button",
  DETAIL_PAGE_DESCRIPTION = "detail_page.description",
  DETAIL_PAGE_ADD_ITEM = "detail_page.add_item",
  DETAIL_PAGE_REMOVE_ITEM = "detail_page.remove_item",
  DETAIL_PAGE_ADD_ITEM_NOTIFICATION = "detail_page.notification_add_item",
  DETAIL_PAGE_COLOR_CHANGED_NOTIFICATION = "detail_page.notification_color_changed",
  SUBMIT_PAGE_HEADER_TITLE = "submit_page.header_title",
  SUBMIT_PAGE_COMMENTS = "submit_page.comments",
  SUBMIT_PAGE_SUBMIT_BUTTON = "submit_page.submit_button",
  SUBMIT_PAGE_ALL_ITEMS_DISCARDED_TITLE = "submit_page.all_items.discarded.title",
  SUBMIT_PAGE_ALL_ITEMS_DISCARDED_DESCRIPTION = "submit_page.all_items.discarded.description",
  SUBMIT_PAGE_ALL_ITEMS_DISCARDED_COMMENTS_DESCRIPTION = "submit_page.all_items.discarded.comments.description",
  SUBMIT_PAGE_ALL_ITEMS_DISCARDED_COMMENTS_PLACEHOLDER = "submit_page.all_items.discarded.comments.placeholder",
  SUBMIT_PAGE_ITEMS_SELECTED_TITLE = "submit_page.items.selected.title",
  SUBMIT_PAGE_ITEMS_SELECTED_DESCRIPTION = "submit_page.items.selected.description",
  SUBMIT_PAGE_ITEMS_SELECTED_COMMENTS = "submit_page.items.selected.comments.description",
  SUBMIT_PAGE_ITEMS_SELECTED_COMMENTS_PLACEHOLDER = "submit_page.items.selected.comments.placeholder",
  SUBMIT_PAGE_ALL_ITEMS_SELECTED_TITLE = "submit_page.all_items.selected.title",
  SUBMIT_PAGE_ALL_ITEMS_SELECTED_DESCRIPTION = "submit_page.all_items.selected.description",
  SUBMIT_PAGE_ALL_ITEMS_SELECTED_COMMENTS = "submit_page.all_items.selected.comments.description",
  SUBMIT_PAGE_ALL_ITEMS_SELECTED_COMMENTS_PLACEHOLDER = "submit_page.all_items.selected.comments.placeholder",
  SUBMIT_PAGE_SUBMITTING_MODAL_TITLE = "submit_page.submitting_modal_title",
  SUBMIT_PAGE_SUBMITTING_MODAL_DESCRIPTION = "submit_page.submitting_modal_description",
  SUBMIT_PAGE_NOTIFICATION_EXPIRED = "submit_page.notification_expired",
  SUBMIT_PAGE_NOTIFICATION_MAX_CHOSEN_PRODUCTS = "submit_page.notification_max_chosen_products",
  SUBMIT_PAGE_NOTIFICATION_SUBMIT_FAILED_BODY = "submit_page.notification_submit_failed_body",
  SUBMIT_PAGE_NOTIFICATION_SUBMIT_FAILED_TITLE = "submit_page.notification_submit_failed_title",
  SUBMIT_PAGE_NOTIFICATION_SUBMITTED = "submit_page.notification_submitted",
  SUBMIT_PAGE_COLOR_CHANGED = "submit_page.color_changed",
  SUBMIT_PAGE_BANNER_TITLE = "submit_page.banner.title",
  SUBMIT_PAGE_BANNER_DESCRIPTION = "submit_page.banner.description",
  SUBMIT_PAGE_BANNER_BUTTON = "submit_page.banner.button",
  SUBMIT_PAGE_LOADING_TEXT = "submit_page.loading.text",
  EXPIRATION_PAGE_TITLE = "expiration_page.title",
  EXPIRATION_PAGE_DESCRIPTION = "expiration_page.description",
  CONFIRMATION_PAGE_TITLE = "confirmation_page.title",
  CONFIRMATION_PAGE_DESCRIPTION = "confirmation_page.description",
  CONFIRMATION_PAGE_SHIPPING_AND_RETURN = "confirmation_page.shipping_and_return",
  FOOTER_PRIVACY = "footer_privacy",
  FOOTER_PRIVACY_URL = "footer_privacy_url",
  FOOTER_TERMS = "footer_terms",
  FOOTER_TERMS_URL = "footer_terms_url",
  OTHER_GARMENTS = "looks.other_garments",
  ONBOARDING_GO_TO_SELECTION_BUTTON = "onboarding.button_go_to_selection",
  ONBOARDING_INTRO_TITLE = "onboarding.intro_title",
  ONBOARDING_INTRO_STEP_1_TITLE = "onboarding.intro_step_1_title",
  ONBOARDING_INTRO_STEP_2_TITLE = "onboarding.intro_step_2_title",
  ONBOARDING_INTRO_STEP_2_HINT = "onboarding.intro_step_2_hint",
  ONBOARDING_INTRO_STEP_3_TITLE = "onboarding.intro_step_3_title",
  ONBOARDING_NEW_USERS_INTRO_TITLE = "onboarding_new_users.intro_title",
  ONBOARDING_NEW_USERS_INTRO_STEP_1_TITLE = "onboarding_new_users.intro_step_1_title",
  ONBOARDING_NEW_USERS_INTRO_STEP_2_TITLE = "onboarding_new_users.intro_step_2_title",
  ONBOARDING_NEW_USERS_INTRO_STEP_3_TITLE = "onboarding_new_users.intro_step_3_title",
  COLOR_AVAILABILITY_NOTIFICATION_TOAST = "color_availability_notification_toast",
}

export {
  PREFIX,
  CATALOG_PREFIX,
  CATALOG_COLOR_PREFIX,
  CATALOG_FEATURE_PREFIX,
  CATALOG_FEATURE_VALUE_PREFIX,
  I18nMessages,
};
