import { useMemo } from "react";
import { QueryStatus, useQuery } from "@lookiero/messaging-react";
import { isBoxPreviewFeedbackUpdated } from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedbackUpdated";
import { BoxPreviewFeedbackProjection } from "../../../../projection/boxPreviewFeedback/boxPreviewFeedback";
import { viewBoxPreviewFeedbackByBoxPreviewId } from "../../../../projection/boxPreviewFeedback/viewBoxPreviewFeedbackByBoxPreviewId";
import {
  BoxPreviewWithFeedbackProjection,
  fromBoxPreviewAndBoxPreviewFeedback,
} from "../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";
import { useViewBoxPreviewById } from "../../boxPreview/react/useViewBoxPreviewById";

interface UseViewBoxPreviewWithFeedbackByIdReturn {
  readonly boxPreviewWithFeedback: BoxPreviewWithFeedbackProjection | undefined;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
  readonly dirty: boolean;
}

interface UseViewBoxPreviewWithFeedbackByIdFunctionArgs {
  readonly boxPreviewId: string;
}

interface UseViewBoxPreviewWithFeedbackByIdFunction {
  (args: UseViewBoxPreviewWithFeedbackByIdFunctionArgs): UseViewBoxPreviewWithFeedbackByIdReturn;
}

const useViewBoxPreviewWithFeedbackById: UseViewBoxPreviewWithFeedbackByIdFunction = ({ boxPreviewId }) => {
  const [boxPreview, boxPreviewStatus] = useViewBoxPreviewById({ boxPreviewId });
  const [boxPreviewFeedback, boxPreviewFeedbackStatus] = useQuery<BoxPreviewFeedbackProjection | undefined>({
    query: viewBoxPreviewFeedbackByBoxPreviewId({ boxPreviewId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: isBoxPreviewFeedbackUpdated,
    options: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: "always",
    },
  });

  return useMemo(
    () => ({
      boxPreviewWithFeedback: boxPreview
        ? fromBoxPreviewAndBoxPreviewFeedback({ boxPreview, boxPreviewFeedback })
        : undefined,
      isFetching: boxPreviewStatus === QueryStatus.LOADING,
      isFetched: boxPreviewFeedbackStatus === QueryStatus.SUCCESS || boxPreviewFeedbackStatus === QueryStatus.ERROR,
      dirty: boxPreviewFeedback ? true : false,
    }),
    [boxPreview, boxPreviewFeedback, boxPreviewStatus, boxPreviewFeedbackStatus],
  );
};

export { useViewBoxPreviewWithFeedbackById };
