import { DomainEvent, MessageName } from "@lookiero/messaging";
import {
  BoxPreviewFeedbackRemoved,
  BOX_PREVIEW_FEEDBACK_REMOVED,
} from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedbackRemoved";
import { StorageBoxPreviewFeedbacksSaveFunction } from "./storageBoxPreviewFeedbacks";

interface StorageBoxPreviewFeedbacksRemoveFunction extends StorageBoxPreviewFeedbacksSaveFunction {}

const isBoxPreviewFeedbackRemoved = (event: DomainEvent<MessageName>): event is BoxPreviewFeedbackRemoved =>
  event.name === BOX_PREVIEW_FEEDBACK_REMOVED;

const storageBoxPreviewFeedbacksRemove: StorageBoxPreviewFeedbacksRemoveFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    const boxPreviewFeedbackRemoved = aggregateRoot.domainEvents.find(isBoxPreviewFeedbackRemoved);

    if (!boxPreviewFeedbackRemoved) {
      return;
    }

    await storage.remove(aggregateRoot.aggregateId);
  };

export { storageBoxPreviewFeedbacksRemove };
