import { HttpPostFunction } from "@lookiero/sty-psp-http";
import { BoxPreviewByIdView, ViewBoxPreviewByIdResult } from "../../../../projection/boxPreview/viewBoxPreviewById";
import { BoxPreviewDto } from "../../../persistence/boxPreviewDto";
import { toBoxPreviewProjection } from "./boxPreview";

interface HttpBoxPreviewByIdView extends BoxPreviewByIdView {}

interface HttpBoxPreviewByIdViewFunctionArgs {
  readonly httpPost: HttpPostFunction;
}

interface HttpBoxPreviewByIdViewFunction {
  (args: HttpBoxPreviewByIdViewFunctionArgs): HttpBoxPreviewByIdView;
}

interface ViewBoxPreviewByIdResponse {
  readonly result: BoxPreviewDto;
}

const httpBoxPreviewByIdView: HttpBoxPreviewByIdViewFunction =
  ({ httpPost }) =>
  async ({ boxPreviewId, signal }) =>
    await httpPost<ViewBoxPreviewByIdResponse, ViewBoxPreviewByIdResult>({
      endpoint: "/view-box-preview-by-id",
      body: { box_preview_id: boxPreviewId },
      signal,
      result: {
        error: null,
        success: (boxPreview) => toBoxPreviewProjection(boxPreview.result),
      },
    });

export { httpBoxPreviewByIdView };
