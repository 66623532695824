import { StyleSheet } from "react-native";

const GAP = 2;

const style = StyleSheet.create({
  carouselContainer: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    flex: "none",
  },
  gallery: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    marginLeft: -GAP / 2,
    marginTop: -GAP / 2,
  },
  item: {
    padding: GAP / 2,
    width: "50%",
  },
});

export { style };
