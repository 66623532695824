import React, { FC, useCallback, useRef } from "react";
import { Pressable, StyleProp, View, ViewStyle } from "react-native";
import { useMediaImage } from "@lookiero/sty-psp-ui";
import { MediaProjection } from "../../../../../projection/media/media";
import { useEnvironment } from "../../../../projection/environment/react/useEnvironment";
import { BoxPreviewImageViewTrackerParams } from "../../../../tracking/tracking";
import { Checkbox } from "../../atoms/checkbox/Checkbox";
import { ProductVariantImage } from "../../molecules/productVariantImage/ProductVariantImage";
import { style } from "./ProductVariant.style";

interface ProductVariantOnChosenFunctionArgs {
  readonly productVariantId: string;
  readonly chosen: boolean;
  readonly isCandidate: boolean;
  readonly look?: string[];
}
interface ProductVariantOnChosenFunction {
  (args: ProductVariantOnChosenFunctionArgs): void;
}

interface ProductVariantOnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): boolean;
}

interface ProductVariantOnPressFunctionArgs {
  readonly productVariantId: string;
  readonly origin: "image" | "description" | "more_colors";
}
interface ProductVariantOnPressFunction {
  (args: ProductVariantOnPressFunctionArgs): void;
}

interface ProductVariantProps {
  readonly productVariantId: string;
  readonly chosen: boolean | null;
  readonly isCandidate: boolean;
  readonly media: MediaProjection;
  readonly mediaSize?: number;
  readonly style?: StyleProp<ViewStyle>;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView?: ProductVariantOnImageViewFunction;
}
const ProductVariant: FC<ProductVariantProps> = ({
  productVariantId,
  chosen,
  isCandidate,
  media,
  mediaSize,
  style: customStyle,
  onChosen,
  onPress,
  onImageView,
}) => {
  const {
    media: {
      sizes: { default: defaultMediaSize },
    },
  } = useEnvironment();
  const cdnImageUrl = useMediaImage();

  const imageViewedRef = useRef(false);
  const handleOnImageView = useCallback(() => {
    if (imageViewedRef.current) {
      return;
    }

    const imageView = onImageView?.({
      productVariantId,
      perspective: media.perspective,
    });

    if (imageView) {
      imageViewedRef.current = true;
    }
  }, [media.perspective, onImageView, productVariantId]);

  const handleOnPress = useCallback(
    () =>
      onPress?.({
        productVariantId,
        origin: "image",
      }),
    [onPress, productVariantId],
  );
  const handleOnChangeCheckbox = useCallback(
    () =>
      onChosen?.({
        productVariantId,
        chosen: !chosen,
        isCandidate,
      }),
    [chosen, isCandidate, onChosen, productVariantId],
  );

  const ImageWrapper = onPress ? Pressable : View;

  return (
    <View style={customStyle}>
      <ImageWrapper onPress={handleOnPress}>
        <ProductVariantImage
          hiResImage={cdnImageUrl({ url: media.url, width: mediaSize || defaultMediaSize })}
          image={cdnImageUrl({ url: media.url, width: mediaSize || defaultMediaSize, dpi: 1 })}
          onImageView={handleOnImageView}
        />
      </ImageWrapper>

      {onChosen && (
        <Pressable style={style.checkbox} onPress={handleOnChangeCheckbox}>
          <Checkbox checked={Boolean(chosen)} value={productVariantId} onChange={handleOnChangeCheckbox} />
        </Pressable>
      )}
    </View>
  );
};

export type { ProductVariantOnChosenFunction, ProductVariantOnImageViewFunction, ProductVariantOnPressFunction };
export { ProductVariant };
