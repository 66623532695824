import { DomainEvent, MessageName } from "@lookiero/messaging";
import { BoxPreviewSubmitted, BOX_PREVIEW_SUBMITTED } from "../../../../domain/boxPreview/model/boxPreviewSubmitted";
import { HttpBoxPreviewsSaveFunction } from "./httpBoxPreviews";

interface HttpBoxPreviewsSubmitFunction extends HttpBoxPreviewsSaveFunction {}

const isBoxPreviewSubmitted = (event: DomainEvent<MessageName>): event is BoxPreviewSubmitted =>
  event.name === BOX_PREVIEW_SUBMITTED;

const httpBoxPreviewsSubmit: HttpBoxPreviewsSubmitFunction =
  ({ httpPost }) =>
  async ({ aggregateId, domainEvents, comment, productVariantIds, replacedFor }) => {
    const boxPreviewSubmitted = domainEvents.find(isBoxPreviewSubmitted);

    if (!boxPreviewSubmitted) {
      return;
    }

    const submitDelay = () => new Promise((resolve) => setTimeout(resolve, 3000));

    await Promise.all([
      submitDelay(),
      httpPost({
        endpoint: "/submit-box-preview-feedback",
        body: {
          box_preview_id: aggregateId,
          comment,
          product_variant_ids: productVariantIds,
          replaced_for: replacedFor,
        },
      }),
    ]);
  };

export { httpBoxPreviewsSubmit };
