import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase } = theme();

const style = StyleSheet.create({
  image: {
    backgroundColor: colorBgBase,
    flex: 1,
  },
});

export { style };
