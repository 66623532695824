import React, { FC, ReactNode } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Column, Row } from "@lookiero/sty-psp-ui";
import { style } from "./Body.style";

type BodyStyle = Partial<Record<"row" | "column", StyleProp<ViewStyle>>>;

interface BodyProps {
  readonly children: ReactNode;
  readonly style?: BodyStyle;
  readonly testID?: string;
}
const Body: FC<BodyProps> = ({ children, style: customStyle, testID }) => (
  <Row style={[style.row, customStyle?.row]} testID={testID}>
    <Column style={[style.column, customStyle?.column]}>{children}</Column>
  </Row>
);

export type { BodyStyle };
export { Body };
