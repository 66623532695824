import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface ColorAvailableFunctionArgs {
  readonly productVariantId: string;
  readonly available: string[] | undefined;
  readonly replaceableNumber: number | undefined;
}
interface ColorAvailableFunction {
  (args: ColorAvailableFunctionArgs): void;
}

interface UseTrackColorAvailableFunctionArgs {
  readonly country: Country | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseTrackColorAvailableFunction {
  (args: UseTrackColorAvailableFunctionArgs): ColorAvailableFunction;
}

const useTrackColorAvailable: UseTrackColorAvailableFunction = ({ country, page, boxPreviewId }) => {
  const tracker = useTracker();

  const colorAvailable: ColorAvailableFunction = useCallback(
    ({ productVariantId, available, replaceableNumber }) => {
      if (!(tracker && country && page && boxPreviewId && productVariantId && available && replaceableNumber)) {
        return;
      }

      tracker.colorAvailable({ country, page, boxPreviewId, productVariantId, available, replaceableNumber });
    },
    [boxPreviewId, country, page, tracker],
  );

  return colorAvailable;
};

export { useTrackColorAvailable };
