import React, { FC } from "react";
import { View } from "react-native";
import { Button, COLOR, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Body } from "../../layouts/body/Body";
import { style } from "./SelectionSummary.style";

interface SelectionSummaryProps {
  readonly count: number;
  readonly onConfirm?: () => void;
}
const SelectionSummary: FC<SelectionSummaryProps> = ({ count, onConfirm }) => {
  const buttonContent = useI18nMessage({ prefix: PREFIX, id: I18nMessages.LANDING_PAGE_SELECT_BUTTON });
  const chosenText = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.LANDING_PAGE_DESCRIPTION_SELECT_PIECES,
    values: { count: `${count}` },
  });
  const chosenTextCount = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.LANDING_PAGE_DESCRIPTION_SELECT_PIECES_COUNT,
    values: { count: `${count}` },
  });

  return (
    <Body style={{ column: style.bodyColumn }} testID="selection-summary">
      <View style={style.content}>
        <Text color={COLOR.TEXT_MEDIUM} level={1} testID="selection-summary-chosen-text" detail>
          {chosenText}
        </Text>
        <Text level={1} testID="selection-summary-chosen-text-count" detail>
          {chosenTextCount}
        </Text>
      </View>

      <View style={style.content}>
        <Button style={style.button} testID="selection-summary-button" onPress={onConfirm}>
          {buttonContent}
        </Button>
      </View>
    </Body>
  );
};

export { SelectionSummary };
