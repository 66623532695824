import React, { FC, useMemo } from "react";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { noMainTransparentMedia } from "../../../../../../../projection/media/media";
import { BoxPreviewImageViewTrackerParams } from "../../../../../../tracking/tracking";
import { ProductVariantOnChosenFunction, ProductVariantOnPressFunction } from "../../../productVariant/ProductVariant";
import { GarmentProductVariant } from "../garmentProductVariant/GarmentProductVariant";

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface GarmentsProductVariantProps {
  readonly productVariant: BoxPreviewWithFeedbackProductVariantProjection;
  readonly looksProductVariants: (BoxPreviewWithFeedbackProductVariantProjection | undefined)[][];
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: OnImageViewFunction;
}
const GarmentsProductVariant: FC<GarmentsProductVariantProps> = ({
  productVariant: boxPreviewProductVariant,
  looksProductVariants,
  onChosen,
  onPress,
  onImageView,
}) => {
  const productVariant = useMemo(
    () => ({
      ...boxPreviewProductVariant,
      media: noMainTransparentMedia(boxPreviewProductVariant.media),
    }),
    [boxPreviewProductVariant],
  );

  return (
    <GarmentProductVariant
      looksProductVariants={looksProductVariants}
      productVariant={productVariant}
      onChosen={onChosen}
      onImageView={onImageView}
      onPress={onPress}
    />
  );
};

export { GarmentsProductVariant };
