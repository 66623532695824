import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_PREVIEW_SUBMITTED = "box_preview_submitted";

interface BoxPreviewSubmittedPayload {
  readonly aggregateId: string;
}

interface BoxPreviewSubmitted extends DomainEvent<typeof BOX_PREVIEW_SUBMITTED>, BoxPreviewSubmittedPayload {}

interface BoxPreviewSubmittedFunction {
  (payload: BoxPreviewSubmittedPayload): BoxPreviewSubmitted;
}

const boxPreviewSubmitted: BoxPreviewSubmittedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: BOX_PREVIEW_SUBMITTED });

const isBoxPreviewSubmitted = (event: DomainEvent<MessageName>): event is BoxPreviewSubmitted =>
  event.name === BOX_PREVIEW_SUBMITTED;

export type { BoxPreviewSubmitted };
export { BOX_PREVIEW_SUBMITTED, boxPreviewSubmitted, isBoxPreviewSubmitted };
