import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase } = theme();

const style = StyleSheet.create({
  lookTemplate: {
    alignItems: "center",
    backgroundColor: colorBgBase,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
  },

  lookTemplateColumn: {
    display: "flex",
    flexDirection: "column",
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    width: "calc(1 / 3 * 100%)",
  },
  lookTemplateEven: {
    width: "50%",
  },
  lookTemplateThree: {
    width: "100%",
  },
  lookTemplateThreeLeft: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    width: "calc(2 / 3 * 100%)",
  },
});

export { style };
