import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { colorBgBase, space05, space3, space4 } = theme();

const style = StyleSheet.create({
  checkbox: {
    padding: space4,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,
  },
  container: {
    backgroundColor: colorBgBase,
    flex: 1,
    gap: space3,
    position: "relative",
  },
  description: {
    gap: space05,
    paddingHorizontal: space3,
  },
});

export { style };
