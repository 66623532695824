import { useCallback } from "react";
import { TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface TrackLooksViewFunction {
  (): void;
}

interface UseLooksViewFunctionArgs {
  readonly country?: string;
  readonly page: TrackingPage;
  readonly boxPreviewId?: string;
}

interface UseLooksViewFunction {
  (args: UseLooksViewFunctionArgs): TrackLooksViewFunction;
}

const useLooksView: UseLooksViewFunction = ({ country, page, boxPreviewId }) => {
  const tracker = useTracker();

  const trackLooksView: TrackLooksViewFunction = useCallback(() => {
    if (!(tracker && country && page && boxPreviewId)) {
      return;
    }

    tracker.looksView({ country, page, boxPreviewId });
  }, [country, page, boxPreviewId, tracker]);

  return trackLooksView;
};

export { useLooksView };
