import { domainEvent, DomainEvent, MessageName } from "@lookiero/messaging";

const BOX_PREVIEW_EXPIRED = "box_preview_expired";

interface BoxPreviewExpiredPayload {
  readonly aggregateId: string;
}

interface BoxPreviewExpired extends DomainEvent<typeof BOX_PREVIEW_EXPIRED>, BoxPreviewExpiredPayload {}

interface BoxPreviewExpiredFunction {
  (payload: BoxPreviewExpiredPayload): BoxPreviewExpired;
}

const boxPreviewExpired: BoxPreviewExpiredFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: BOX_PREVIEW_EXPIRED });

const isBoxPreviewExpired = (event: DomainEvent<MessageName>): event is BoxPreviewExpired =>
  event.name === BOX_PREVIEW_EXPIRED;

export type { BoxPreviewExpired };
export { BOX_PREVIEW_EXPIRED, boxPreviewExpired, isBoxPreviewExpired };
