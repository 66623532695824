import invariant from "tiny-invariant";
import { AggregateRoot, CommandHandlerFunction } from "@lookiero/messaging";
import { SubmitBoxPreview } from "../command/submitBoxPreview";
import { boxPreviewExpired } from "./boxPreviewExpired";
import { boxPreviewMaxChosenProductsReached } from "./boxPreviewMaxChosenProductsReached";
import { boxPreviewSubmitted } from "./boxPreviewSubmitted";

const MAX_CHOSEN_PRODUCT_VARIANTS = 5;

enum BoxPreviewStatus {
  SENT = "SENT",
  NOTIFIED = "NOTIFIED",
  EXPIRED = "EXPIRED",
  SUBMITTED = "SUBMITTED",
}

interface BoxPreview extends AggregateRoot {
  readonly boxId: string;
  readonly productVariantIds: string[];
  readonly expiration: Date;
  readonly status: BoxPreviewStatus;
  readonly comment: string;
  readonly replacedFor: Record<string, string>;
}

const submitBoxPreviewHandler: CommandHandlerFunction<SubmitBoxPreview, BoxPreview> =
  () =>
  async ({ aggregateRoot, command }) => {
    const { aggregateId, productVariantIds, currentDate, comment, replacedFor } = command;

    invariant(aggregateRoot.status !== BoxPreviewStatus.SUBMITTED, "BoxPreview is already submitted");

    if (productVariantIds.length > MAX_CHOSEN_PRODUCT_VARIANTS) {
      return {
        ...aggregateRoot,
        domainEvents: [boxPreviewMaxChosenProductsReached({ aggregateId })],
      };
    }

    if (currentDate > aggregateRoot.expiration) {
      return {
        ...aggregateRoot,
        domainEvents: [boxPreviewExpired({ aggregateId })],
      };
    }

    return {
      ...aggregateRoot,
      status: BoxPreviewStatus.SUBMITTED,
      productVariantIds,
      comment,
      replacedFor,
      domainEvents: [boxPreviewSubmitted({ aggregateId })],
    };
  };

export type { BoxPreview };
export { BoxPreviewStatus, submitBoxPreviewHandler, MAX_CHOSEN_PRODUCT_VARIANTS };
