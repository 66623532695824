import React, { FC } from "react";
import Svg, { Rect } from "react-native-svg";

interface DoubleHalfProps {
  readonly width: number;
  readonly height: number;
}

const DoubleHalf: FC<DoubleHalfProps> = ({ width, height }) => (
  <Svg fill="none" height={height} viewBox="0 0 240 309" width={width}>
    <Rect fill="#3DA490" height="240" rx="77.4468" transform="rotate(-90 0 154.895)" width="154.894" y="154.895" />
    <Rect fill="#3DA490" height="240" rx="77.4468" transform="rotate(-90 0 308.344)" width="154.894" y="308.344" />
  </Svg>
);

export { DoubleHalf };
