enum Slugs {
  EXPIRED = "expired",
  SUBMITTED = "thank-you",
  FEEDBACK = "feedback",
  ITEMS = "items",
  ITEMS_TABS = "tabs/:tab",
  ITEM = ":productVariantId",
}

const basePath = "/:language/:id";

enum Routes {
  HOME = basePath,
  EXPIRED = `${basePath}/${Slugs.EXPIRED}`,
  SUBMITTED = `${basePath}/${Slugs.SUBMITTED}`,
  FEEDBACK = `${basePath}/${Slugs.FEEDBACK}`,
  ITEMS = `${basePath}/${Slugs.ITEMS}`,
  ITEMS_TABS = `${basePath}/${Slugs.ITEMS}/${Slugs.ITEMS_TABS}`,
  ITEM = `${basePath}/${Slugs.ITEMS}/${Slugs.ITEMS_TABS}/${Slugs.ITEM}`,
}

type Tab = "garments" | "looks";

export type { Tab };
export { Routes, Slugs };
