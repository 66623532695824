import { DomainEvent, MessageName } from "@lookiero/messaging";
import { useQuery, UseQueryFunctionResult } from "@lookiero/messaging-react";
import { isBoxPreviewExpired } from "../../../../domain/boxPreview/model/boxPreviewExpired";
import { isBoxPreviewSubmitted } from "../../../../domain/boxPreview/model/boxPreviewSubmitted";
import { BoxPreviewProjection } from "../../../../projection/boxPreview/boxPreview";
import { viewBoxPreviewById } from "../../../../projection/boxPreview/viewBoxPreviewById";
import { MESSAGING_CONTEXT_ID } from "../../../delivery/baseBootstrap";

const shouldInvalidate = (event: DomainEvent<MessageName>) =>
  isBoxPreviewSubmitted(event) || isBoxPreviewExpired(event);

interface UseViewBoxPreviewByIdFunctionArgs {
  readonly boxPreviewId: string;
}

interface UseViewBoxPreviewByIdFunction {
  (args: UseViewBoxPreviewByIdFunctionArgs): UseQueryFunctionResult<BoxPreviewProjection>;
}

const useViewBoxPreviewById: UseViewBoxPreviewByIdFunction = ({ boxPreviewId }) =>
  useQuery({
    query: viewBoxPreviewById({ boxPreviewId }),
    contextId: MESSAGING_CONTEXT_ID,
    invalidation: shouldInvalidate,
    options: { staleTime: Infinity, retry: false, refetchOnWindowFocus: false },
  });

export { useViewBoxPreviewById };
