import VisibilitySensor from "@svanboxel/visibility-sensor-react-native";
import React, { FC, useCallback, useRef, useEffect } from "react";
import { AspectRatioView, LazyImage } from "@lookiero/sty-psp-ui";
import { style } from "./ProductVariantImage.style";

interface ProductVariantImageProps {
  readonly image: string;
  readonly hiResImage?: string;
  readonly onImageView?: () => void;
}
const ProductVariantImage: FC<ProductVariantImageProps> = ({
  image,
  hiResImage,
  onImageView,
}: ProductVariantImageProps) => {
  const mountedRef = useRef(true);
  const visibilitySensorStyle = { style: { flex: 1 } };
  const handleImageVisibility = useCallback(
    (isVisible: boolean) => {
      if (!mountedRef.current) {
        return;
      }

      if (isVisible) {
        onImageView?.();
      }
    },
    [onImageView],
  );

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return (
    <AspectRatioView aspectRatio={1.25}>
      <VisibilitySensor onChange={handleImageVisibility} {...visibilitySensorStyle}>
        <LazyImage
          hiResSrc={hiResImage}
          resizeMode="cover"
          src={image}
          style={{ view: style.image }}
          testID="product-variant-image"
        />
      </VisibilitySensor>
    </AspectRatioView>
  );
};

export { ProductVariantImage };
