import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4 } = theme();

const style = StyleSheet.create({
  container: {
    marginBottom: space4,
  },
});

export { style };
