import { useCallback } from "react";
import { Country } from "@lookiero/sty-psp-locale";
import { TrackerClickItemOrigin, TrackingPage } from "./tracking";
import { useTracker } from "./useTracker";

interface ClickItemFunctionArgs {
  readonly productVariantId: string;
  readonly origin: TrackerClickItemOrigin;
}

interface ClickItemFunction {
  (args: ClickItemFunctionArgs): void;
}

interface UseTrackClickItemFunctionArgs {
  readonly country: Country | undefined;
  readonly page: TrackingPage;
  readonly boxPreviewId: string | undefined;
}

interface UseTrackClickItemFunction {
  (args: UseTrackClickItemFunctionArgs): ClickItemFunction;
}

const useTrackClickItem: UseTrackClickItemFunction = ({ country, page, boxPreviewId }) => {
  const tracker = useTracker();

  const clickItem: ClickItemFunction = useCallback(
    ({ productVariantId, origin }) => {
      if (!(tracker && country && page && boxPreviewId)) {
        return;
      }

      tracker.clickItem({ country, page, boxPreviewId, productVariantId, origin });
    },
    [boxPreviewId, country, page, tracker],
  );

  return clickItem;
};

export { useTrackClickItem };
