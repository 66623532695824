import { StyleSheet } from "react-native";

const style = StyleSheet.create({
  column: {
    flex: 1,
  },
  row: {
    flex: 1,
    justifyContent: "center",
  },
});

export { style };
