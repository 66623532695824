import React, { FC, useCallback } from "react";
import { Linking, View } from "react-native";
import { COLOR, Link } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { style } from "./Footer.style";

const Footer: FC = () => {
  const privacy = useI18nMessage({ prefix: PREFIX, id: I18nMessages.FOOTER_PRIVACY });
  const privacyUrl = useI18nMessage({ prefix: PREFIX, id: I18nMessages.FOOTER_PRIVACY_URL });
  const terms = useI18nMessage({ prefix: PREFIX, id: I18nMessages.FOOTER_TERMS });
  const termsUrl = useI18nMessage({ prefix: PREFIX, id: I18nMessages.FOOTER_TERMS_URL });

  const handleOnPressLink = useCallback((url: string) => Linking.openURL(url), []);

  return (
    <View style={style.footer}>
      <Link
        color={COLOR.TEXT_LIGHT}
        level={2}
        testID="footer-privacy-link"
        detail
        onPress={() => handleOnPressLink(privacyUrl)}
      >
        {privacy}
      </Link>

      <Link
        color={COLOR.TEXT_LIGHT}
        level={2}
        testID="footer-terms-link"
        detail
        onPress={() => handleOnPressLink(termsUrl)}
      >
        {terms}
      </Link>
    </View>
  );
};

export { Footer };
