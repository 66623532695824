import React, { FC, useCallback, useMemo } from "react";
import { View } from "react-native";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { useScreenSize } from "@lookiero/sty-psp-ui";
import { LooksProjection } from "../../../../../projection/boxPreview/boxPreview";
import { BoxPreviewWithFeedbackProductVariantProjection } from "../../../../../projection/boxPreviewWithFeedback/model/boxPreviewWithFeedback";
import { BoxPreviewImageViewTrackerParams } from "../../../../tracking/tracking";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Body } from "../../layouts/body/Body";
import { OtherGarmentsProductVariants } from "../otherGarmentsProductVariants/OtherGarmentsProductVariants";
import {
  ProductVariantOnChosenFunction,
  ProductVariantOnImageViewFunction,
  ProductVariantOnPressFunction,
} from "../productVariant/ProductVariant";
import { style } from "./LooksProductVariants.style";
import { LookProductVariants } from "./components/LookProductVariants";

interface OnImageViewFunction {
  (args: BoxPreviewImageViewTrackerParams): void;
}

interface LooksProductVariantsProps {
  readonly productVariants: BoxPreviewWithFeedbackProductVariantProjection[];
  readonly looks: LooksProjection | undefined;
  readonly onChosen?: ProductVariantOnChosenFunction;
  readonly onPress?: ProductVariantOnPressFunction;
  readonly onImageView: OnImageViewFunction;
}
const LooksProductVariants: FC<LooksProductVariantsProps> = ({
  looks,
  productVariants,
  onChosen,
  onPress,
  onImageView,
}) => {
  const screenSize = useScreenSize();
  const lookScreenStyle = screenSize === "S" ? style.lookSmall : style.lookDefault;

  const otherGarmentsText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.OTHER_GARMENTS });

  const looksProductVariants = useMemo(
    () =>
      looks?.map((look) =>
        look.map((productVariantId: string) =>
          productVariants.find((productVariant) => productVariant.id === productVariantId),
        ),
      ),
    [looks, productVariants],
  );

  const otherGarments = useMemo(
    () => productVariants.filter((productVariant) => looks?.every((look) => !look.includes(productVariant.id))),
    [looks, productVariants],
  );

  const handleOnImageView: ProductVariantOnImageViewFunction = useCallback(
    ({ perspective, productVariantId, look, lookProductVariantId }) => {
      onImageView?.({ perspective, productVariantId, look, lookProductVariantId });

      return true;
    },
    [onImageView],
  );

  return (
    <Body style={{ row: style.bodyRow, column: style.bodyColumn }} testID="looks-product-variants">
      {looksProductVariants?.map((lookProductVariants, index) => (
        <View key={index} style={style.look} testID="look-product-variants">
          <LookProductVariants
            lookProductVariants={lookProductVariants}
            style={{
              look: { lookTemplate: [style.lookTemplate, lookScreenStyle] },
            }}
            onChosen={onChosen}
            onImageView={handleOnImageView}
            onPress={onPress}
          />
        </View>
      ))}

      {otherGarments.length > 0 && (
        <View style={style.otherGarments} testID="other-garments">
          <Text level={2} style={style.otherGarmentsTitle} action>
            {otherGarmentsText}
          </Text>

          <OtherGarmentsProductVariants
            productVariants={otherGarments}
            onChosen={onChosen}
            onImageView={handleOnImageView}
            onPress={onPress}
          />
        </View>
      )}
    </Body>
  );
};

export { LooksProductVariants };
