import React, { FC } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { Text } from "@lookiero/aurora";
import { style } from "./Title.style";

interface TitleProps {
  readonly children: string;
  readonly testID?: string;
  readonly style?: StyleProp<ViewStyle>;
}

const Title: FC<TitleProps> = ({ children, testID, style: customStyle }) => (
  <Text level={3} style={[style.text, customStyle]} testID={testID} heading>
    {children}
  </Text>
);

export { Title };
