import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { borderRadius3, space2 } = theme();

const IMAGE_WIDTH = 59;
const IMAGE_HEIGHT = 74;

const style = StyleSheet.create({
  container: {
    flexGrow: 0,
    flexShrink: 0,
    paddingVertical: space2,
  },
  contentContainer: {
    gap: space2,
    height: IMAGE_HEIGHT,
  },
  productVariant: {
    borderRadius: borderRadius3,
    flex: 1,
    overflow: "hidden",
    width: IMAGE_WIDTH,
  },
});

export { style };
