enum MediaPerspective {
  BACK = "BACK",
  BOTTOM_UP = "BOTTOM_UP",
  COLLAGE = "COLLAGE",
  DETAIL = "DETAIL",
  FLAT_BACK = "FLAT_BACK",
  FRONT = "FRONT",
  FRONT_FAR = "FRONT_FAR",
  LABEL = "LABEL",
  MAIN = "MAIN",
  MAIN_TRANSPARENT = "MAIN_TRANSPARENT",
  OTHER = "OTHER",
  SIDE = "SIDE",
}

interface MediaProjection {
  readonly url: string;
  readonly perspective: MediaPerspective;
}

const mainTransparentImageFromMedia = (media: MediaProjection[] | undefined): MediaProjection =>
  media?.find((media) => media.perspective === MediaPerspective.MAIN_TRANSPARENT) || mainImageFromMedia(media);

const mainImageFromMedia = (media: MediaProjection[] | undefined): MediaProjection =>
  media?.find((media) => media.perspective === MediaPerspective.MAIN) || mediaFallback(media);

const mediaFallback = (media: MediaProjection[] | undefined): MediaProjection =>
  media?.[0] || { perspective: MediaPerspective.MAIN, url: "" };

type NoMainMediaResult<Media extends MediaProjection[] | undefined> = Media extends infer MediaResult
  ? MediaResult
  : never;

const noMainMedia = <Media extends MediaProjection[] | undefined>(media: Media): NoMainMediaResult<Media> =>
  media?.every(({ perspective }) => perspective !== MediaPerspective.MAIN)
    ? (media as NoMainMediaResult<Media>)
    : (media?.filter(({ perspective }) => perspective !== MediaPerspective.MAIN) as NoMainMediaResult<Media>);

type NoMainTransparentMediaResult<Media extends MediaProjection[] | undefined> = Media extends infer MediaResult
  ? MediaResult
  : never;

const noMainTransparentMedia = <Media extends MediaProjection[] | undefined>(
  media: Media,
): NoMainTransparentMediaResult<Media> =>
  media?.every(({ perspective }) => perspective !== MediaPerspective.MAIN_TRANSPARENT)
    ? (media as NoMainTransparentMediaResult<Media>)
    : (media?.filter(
        ({ perspective }) => perspective !== MediaPerspective.MAIN_TRANSPARENT,
      ) as NoMainTransparentMediaResult<Media>);

export { mainTransparentImageFromMedia, mainImageFromMedia, MediaPerspective, noMainMedia, noMainTransparentMedia };
export type { MediaProjection };
