import React, { FC } from "react";
import { View } from "react-native";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { Step } from "../../atoms/step/Step";
import { Title } from "../../atoms/title/Title";
import { style } from "./IntroSteps.style";

const IntroSteps: FC = () => {
  const title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.ONBOARDING_INTRO_TITLE });
  const step1Title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.ONBOARDING_INTRO_STEP_1_TITLE });
  const step2Title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.ONBOARDING_INTRO_STEP_2_TITLE });
  const step3Title = useI18nMessage({ prefix: PREFIX, id: I18nMessages.ONBOARDING_INTRO_STEP_3_TITLE });

  return (
    <View testID="intro-steps">
      <Title>{title}</Title>
      <View style={style.container}>
        <Step index={1} style={style.step}>
          {step1Title}
        </Step>
        <Step index={2} style={style.step}>
          {step2Title}
        </Step>
        <Step index={3}>{step3Title}</Step>
      </View>
    </View>
  );
};

export { IntroSteps };
