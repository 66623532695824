import { Country } from "@lookiero/sty-psp-locale";
import { BoxPreviewStatus } from "../../../../domain/boxPreview/model/boxPreview";
import {
  BoxPreviewProductVariantProjection,
  BoxPreviewProjection,
  ReplaceableByProjection,
} from "../../../../projection/boxPreview/boxPreview";
import { Currency } from "../../../../projection/currency/currency";
import { FeatureProjection, FeatureType, FeatureUnit } from "../../../../projection/feature/feature";
import { MediaPerspective, MediaProjection } from "../../../../projection/media/media";
import { PriceProjection } from "../../../../projection/price/price";
import { BoxPreviewDto } from "../../../persistence/boxPreviewDto";
import { BoxPreviewProductVariantDto } from "../../../persistence/boxPreviewProductVariantDto";
import { FeatureDto } from "../../../persistence/featureDto";
import { MediaDto } from "../../../persistence/mediaDto";
import { PriceDto } from "../../../persistence/priceDto";
import { ReplaceableByDto } from "../../../persistence/replaceableByDto";

interface ToMediaProjectionFunction {
  (mediaDto: MediaDto): MediaProjection;
}

const toMediaProjection: ToMediaProjectionFunction = (mediaDto) => ({
  url: mediaDto.url,
  perspective: mediaDto.perspective as MediaPerspective,
});

interface ToFeatureProjectionFunction {
  (featureDto: FeatureDto): FeatureProjection;
}

const toFeatureProjection: ToFeatureProjectionFunction = (featureDto) => ({
  id: featureDto.id,
  multiple: featureDto.multiple,
  name: featureDto.name,
  type: featureDto.type as FeatureType,
  unit: featureDto.unit as FeatureUnit,
  values: featureDto.values.map((value) => ({
    id: value.id,
    value: value.value,
    qualifier: value.qualifier ? toFeatureProjection(value.qualifier) : null,
  })),
});

interface ToReplaceableByProjectionFunction {
  (replaceableBy: ReplaceableByDto): ReplaceableByProjection;
}

const toReplaceableByProjectionFunction: ToReplaceableByProjectionFunction = (replaceableBy) => ({
  id: replaceableBy.id,
  color: replaceableBy.color,
  media: replaceableBy.media ? replaceableBy.media.map(toMediaProjection) : [],
});

interface ToPriceProjectionFunction {
  (priceDto: PriceDto): PriceProjection;
}

const toPriceProjection: ToPriceProjectionFunction = (priceDto) => ({
  amount: priceDto.amount,
  currency: priceDto.currency as Currency,
  discountedPrice: priceDto.discounted_price && {
    amount: priceDto.discounted_price.amount,
    percentage: priceDto.discounted_price.percentage,
  },
});

interface ToBoxPreviewProductVariantProjectionFunctionArgs {
  readonly boxPreviewProductVariantDto: BoxPreviewProductVariantDto;
}
interface ToBoxPreviewProductVariantProjectionFunction {
  (args: ToBoxPreviewProductVariantProjectionFunctionArgs): BoxPreviewProductVariantProjection;
}

const toBoxPreviewProductVariantProjectionFunction: ToBoxPreviewProductVariantProjectionFunction = ({
  boxPreviewProductVariantDto,
}) => ({
  id: boxPreviewProductVariantDto.id,
  media: boxPreviewProductVariantDto.media ? boxPreviewProductVariantDto.media.map(toMediaProjection) : [],
  chosen: boxPreviewProductVariantDto.selected,
  title: boxPreviewProductVariantDto.title,
  brand: boxPreviewProductVariantDto.brand,
  price: boxPreviewProductVariantDto.price ? toPriceProjection(boxPreviewProductVariantDto.price) : null,
  isCandidate: boxPreviewProductVariantDto.is_candidate,
  color: boxPreviewProductVariantDto.color,
  features: boxPreviewProductVariantDto.features ? boxPreviewProductVariantDto.features.map(toFeatureProjection) : [],
  replaceableBy: boxPreviewProductVariantDto.replaceable_by
    ? boxPreviewProductVariantDto.replaceable_by.map(toReplaceableByProjectionFunction)
    : [],
});

interface ToBoxPreviewProjectionFunction {
  (boxPreviewDto: BoxPreviewDto): BoxPreviewProjection;
}

const toBoxPreviewProjection: ToBoxPreviewProjectionFunction = (boxPreviewDto) => {
  const status = boxPreviewDto.status as BoxPreviewStatus;

  return {
    id: boxPreviewDto.id,
    boxId: boxPreviewDto.box_id,
    countryCode: boxPreviewDto.country_code as Country,
    status,
    comment: boxPreviewDto.comment,
    expiration: new Date(boxPreviewDto.expiration),
    productVariants: boxPreviewDto.product_variants.map((productVariant) =>
      toBoxPreviewProductVariantProjectionFunction({
        boxPreviewProductVariantDto: productVariant,
      }),
    ),
    looks: boxPreviewDto.looks,
    customerId: boxPreviewDto.customer_id,
    replacedFor: boxPreviewDto.replaced_for || {},
    isRecurrent: boxPreviewDto.is_recurrent,
  };
};

export { toBoxPreviewProjection };
