import React, { FC, ReactNode, useCallback } from "react";
import { generatePath, useNavigate, useParams } from "react-router";
import { useUpdateBoxPreviewFeedback } from "../../../domain/boxPreviewFeedback/react/useUpdateBoxPreviewFeedback";
import { useViewBoxPreviewWithFeedbackById } from "../../../projection/boxPreviewFeedback/react/useViewBoxPreviewWithFeedbackById";
import { BoxPreviewImageViewTrackerParams, TrackingPage } from "../../../tracking/tracking";
import { useBoxPreviewImageView } from "../../../tracking/useBoxPreviewImageView";
import { useChosenProductVariant } from "../../../tracking/useChosenProductVariant";
import { useLooksView } from "../../../tracking/useLooksView";
import { useTrackClickItem } from "../../../tracking/useTrackClickItem";
import { ProductVariantOnPressFunction } from "../../components/organisms/productVariant/ProductVariant";
import { ItemsTabs as ItemsTabsTemplate } from "../../components/templates/itemsTabs/ItemsTabs";
import { Routes, Tab } from "../../routing/routes";

interface HandleOnChosenFunctionArgs {
  readonly page: TrackingPage;
  readonly productVariantId: string;
  readonly chosen: boolean;
  readonly look?: string[];
  readonly isCandidate: boolean;
}
interface HandleOnChosenFunction {
  (args: HandleOnChosenFunctionArgs): void;
}

interface ItemsTabsProps {
  readonly children: ReactNode;
}

const ItemsTabs: FC<ItemsTabsProps> = ({ children }) => {
  const { id, language, tab } = useParams();

  const { boxPreviewWithFeedback, isFetching: boxPreviewWithFeedbackIsFetching } = useViewBoxPreviewWithFeedbackById({
    boxPreviewId: id as string,
  });

  const { updateChosenProductVariant } = useUpdateBoxPreviewFeedback({ boxPreviewWithFeedback });
  const trackChosenProductVariant = useChosenProductVariant({
    country: boxPreviewWithFeedback?.countryCode,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  const handleOnChosen: HandleOnChosenFunction = useCallback(
    ({ page, productVariantId, chosen, look, isCandidate }) => {
      updateChosenProductVariant({ productVariantId, chosen });
      trackChosenProductVariant({ page, productVariantId, chosen, look, isCandidate, auto: false });
    },
    [trackChosenProductVariant, updateChosenProductVariant],
  );

  const navigate = useNavigate();
  const trackClickItem = useTrackClickItem({
    boxPreviewId: boxPreviewWithFeedback?.id,
    country: boxPreviewWithFeedback?.countryCode,
    page: TrackingPage.ITEMS,
  });
  const handleOnPress: ProductVariantOnPressFunction = useCallback(
    ({ origin, productVariantId }) => {
      navigate(
        generatePath(Routes.ITEM, {
          id: id as string,
          language: language as string,
          tab: tab as string,
          productVariantId,
        }),
      );
      trackClickItem({
        productVariantId,
        origin,
      });
    },
    [id, language, navigate, tab, trackClickItem],
  );

  const trackLooksView = useLooksView({
    country: boxPreviewWithFeedback?.countryCode,
    page: TrackingPage.ITEMS,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  const handleOnTabChanged = useCallback(
    (active: number) => {
      const activeTab: Tab = active === 1 ? "garments" : "looks";

      if (active === 0) {
        trackLooksView();
      }

      if (activeTab === tab) {
        return;
      }

      navigate(generatePath(Routes.ITEMS_TABS, { id: id as string, language: language as string, tab: activeTab }), {
        replace: true,
      });
    },
    [tab, navigate, id, language, trackLooksView],
  );

  const trackBoxPreviewImageView = useBoxPreviewImageView({
    country: boxPreviewWithFeedback?.countryCode,
    boxPreviewId: boxPreviewWithFeedback?.id,
  });
  const handleOnImageView = useCallback(
    ({ page, productVariantId, perspective, look, lookProductVariantId, swipe }: BoxPreviewImageViewTrackerParams) => {
      trackBoxPreviewImageView({
        page,
        productVariantId,

        perspective,
        look,
        lookProductVariantId,
        swipe,
      });
    },
    [trackBoxPreviewImageView],
  );

  const dependenciesLoading = boxPreviewWithFeedbackIsFetching || !boxPreviewWithFeedback;

  if (dependenciesLoading) {
    return null;
  }

  return (
    <>
      <ItemsTabsTemplate
        boxPreviewWithFeedback={boxPreviewWithFeedback}
        tabIndex={tab === "looks" ? 0 : 1}
        onChosen={handleOnChosen}
        onImageView={handleOnImageView}
        onPress={handleOnPress}
        onTabChanged={handleOnTabChanged}
      />
      {children}
    </>
  );
};

export { ItemsTabs };
