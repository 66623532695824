import React, { FC } from "react";
import { COLOR, Text } from "@lookiero/aurora";
import { style } from "./Description.style";

interface DescriptionProps {
  readonly children: string;
  readonly color?: COLOR;
  readonly testID?: string;
}
const Description: FC<DescriptionProps> = ({ children, color = COLOR.TEXT_MEDIUM, testID }) => (
  <Text color={color} level={3} style={style.text} testID={testID}>
    {children}
  </Text>
);

export { Description };
