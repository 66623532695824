import { Storage } from "@lookiero/sty-psp-storage";
import { BoxPreviewFeedbackByBoxPreviewIdView } from "../../../../projection/boxPreviewFeedback/viewBoxPreviewFeedbackByBoxPreviewId";
import { BoxPreviewFeedbackDto } from "../../../persistence/boxPreviewFeedbackDto";
import { toBoxPreviewFeedbackFunction } from "./boxPreviewFeedback";

interface StorageBoxPreviewFeedbackByBoxPreviewIdView extends BoxPreviewFeedbackByBoxPreviewIdView {}

interface StorageBoxPreviewFeedbackByBoxPreviewIdViewFunctionArgs {
  readonly storage: Storage<BoxPreviewFeedbackDto>;
}

interface StorageBoxPreviewFeedbackByBoxPreviewIdViewFunction {
  (args: StorageBoxPreviewFeedbackByBoxPreviewIdViewFunctionArgs): StorageBoxPreviewFeedbackByBoxPreviewIdView;
}

const storageBoxPreviewFeedbackByBoxPreviewIdView: StorageBoxPreviewFeedbackByBoxPreviewIdViewFunction =
  ({ storage }) =>
  async ({ boxPreviewId }) => {
    try {
      const boxPreviewFeedbackDto = await storage.read(boxPreviewId);

      return boxPreviewFeedbackDto ? toBoxPreviewFeedbackFunction(boxPreviewFeedbackDto) : null;
    } catch (ignored) {
      throw new Error("Could not fetch the boxPreviewFeedback");
    }
  };

export { storageBoxPreviewFeedbackByBoxPreviewIdView };
