import { ProcessManagerFunction } from "@lookiero/messaging";
import { removeBoxPreviewFeedback } from "../../boxPreviewFeedback/command/removeBoxPreviewFeedback";
import { BoxPreviewSubmitted } from "../model/boxPreviewSubmitted";

interface RemoveBoxPreviewFeedbackWhenBoxPreviewSubmittedFunction extends ProcessManagerFunction<BoxPreviewSubmitted> {}

const removeBoxPreviewFeedbackWhenBoxPreviewSubmitted: RemoveBoxPreviewFeedbackWhenBoxPreviewSubmittedFunction =
  ({ commandBus }) =>
  async ({ aggregateId }) => {
    await commandBus(removeBoxPreviewFeedback({ aggregateId }));
  };

export { removeBoxPreviewFeedbackWhenBoxPreviewSubmitted };
