import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space4 } = theme();

const style = StyleSheet.create({
  checkbox: {
    padding: space4,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,
  },
});

export { style };
