import React, { FC } from "react";
import { View } from "react-native";
import { Icon, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../../i18n/i18n";
import { Body } from "../../../layouts/body/Body";
import { style } from "./Footer.style";

const Footer: FC = () => {
  const shippingAndReturnsText = useI18nMessage({
    prefix: PREFIX,
    id: I18nMessages.CONFIRMATION_PAGE_SHIPPING_AND_RETURN,
  });

  return (
    <Body style={{ row: style.bodyRow }}>
      <View style={style.row}>
        <Icon name="truck" />

        <Text level={1} detailBold>
          {shippingAndReturnsText}
        </Text>
      </View>
    </Body>
  );
};
export { Footer };
