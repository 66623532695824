import { StyleSheet } from "react-native";
import { theme } from "@lookiero/sty-psp-ui";

const { space025, space4 } = theme();

const style = StyleSheet.create({
  bodyRow: {
    paddingHorizontal: 0,
  },
  columnWrapper: {
    gap: space025,
  },
  contentContainer: {
    gap: space4,
  },
});

export { style };
