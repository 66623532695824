import { DomainEvent, MessageName } from "@lookiero/messaging";
import { BoxPreviewFeedback } from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedback";
import {
  BoxPreviewFeedbackUpdated,
  BOX_PREVIEW_FEEDBACK_UPDATED,
} from "../../../../domain/boxPreviewFeedback/model/boxPreviewFeedbackUpdated";
import { BoxPreviewFeedbackDto } from "../../../persistence/boxPreviewFeedbackDto";
import { StorageBoxPreviewFeedbacksSaveFunction } from "./storageBoxPreviewFeedbacks";

const fromDomain = (boxPreviewFeedback: BoxPreviewFeedback): BoxPreviewFeedbackDto => ({
  boxPreviewId: boxPreviewFeedback.aggregateId,
  chosenProductVariantIds: boxPreviewFeedback.chosenProductVariantIds || [],
  comment: boxPreviewFeedback.comment || "",
  replacedFor: boxPreviewFeedback.replacedFor || {},
});

interface StorageBoxPreviewFeedbacksUpdateFunction extends StorageBoxPreviewFeedbacksSaveFunction {}

const isBoxPreviewFeedbackUpdated = (event: DomainEvent<MessageName>): event is BoxPreviewFeedbackUpdated =>
  event.name === BOX_PREVIEW_FEEDBACK_UPDATED;

const storageBoxPreviewFeedbacksUpdate: StorageBoxPreviewFeedbacksUpdateFunction =
  ({ storage }) =>
  async (aggregateRoot) => {
    const boxPreviewFeedbackUpdated = aggregateRoot.domainEvents.find(isBoxPreviewFeedbackUpdated);

    if (!boxPreviewFeedbackUpdated) {
      return;
    }

    await storage.write(aggregateRoot.aggregateId, fromDomain(aggregateRoot));
  };

export { storageBoxPreviewFeedbacksUpdate };
