import { BoxPreviewFeedbackProjection } from "../../../../projection/boxPreviewFeedback/boxPreviewFeedback";
import { BoxPreviewFeedbackDto } from "../../../persistence/boxPreviewFeedbackDto";

interface ToBoxPreviewFeedbackFunction {
  (boxPreviewFeedbackDto: BoxPreviewFeedbackDto): BoxPreviewFeedbackProjection;
}

const toBoxPreviewFeedbackFunction: ToBoxPreviewFeedbackFunction = (boxPreviewFeedbackDto) => ({
  boxPreviewId: boxPreviewFeedbackDto.boxPreviewId,
  chosenProductVariantIds: boxPreviewFeedbackDto.chosenProductVariantIds,
  comment: boxPreviewFeedbackDto.comment,
  replacedFor: boxPreviewFeedbackDto.replacedFor,
});

export { toBoxPreviewFeedbackFunction };
