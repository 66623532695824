import { domainEvent, DomainEvent } from "@lookiero/messaging";

const BOX_PREVIEW_FEEDBACK_REMOVED = "box_preview_feedback_removed";

interface BoxPreviewFeedbackRemovedPayload {
  readonly aggregateId: string;
}

interface BoxPreviewFeedbackRemoved
  extends DomainEvent<typeof BOX_PREVIEW_FEEDBACK_REMOVED>,
    BoxPreviewFeedbackRemovedPayload {}

interface BoxPreviewFeedbackRemovedFunction {
  (payload: BoxPreviewFeedbackRemovedPayload): BoxPreviewFeedbackRemoved;
}

const boxPreviewFeedbackRemoved: BoxPreviewFeedbackRemovedFunction = ({ aggregateId }) =>
  domainEvent({ aggregateId, name: BOX_PREVIEW_FEEDBACK_REMOVED });

export type { BoxPreviewFeedbackRemoved };
export { BOX_PREVIEW_FEEDBACK_REMOVED, boxPreviewFeedbackRemoved };
