import React, { FC } from "react";
import { Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../i18n/i18n";
import { ShapesSpinner } from "../../atoms/shapesSpinner/ShapesSpinner";
import { Body } from "../../layouts/body/Body";
import { style } from "./Loading.style";

const Loading: FC = () => {
  const text = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_LOADING_TEXT });

  return (
    <Body style={{ column: style.bodyColumn }}>
      <ShapesSpinner />
      <Text level={2} style={style.text} action>
        {text}
      </Text>
    </Body>
  );
};

export { Loading };
