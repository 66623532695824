import { LinearGradient } from "expo-linear-gradient";
import React, { FC } from "react";
import { ImageBackground, Pressable, StyleSheet, View } from "react-native";
import { BUTTON_VARIANT, Button, Text } from "@lookiero/aurora";
import { useI18nMessage } from "@lookiero/i18n-react";
import { I18nMessages, PREFIX } from "../../../../i18n/i18n";
import { Body } from "../../../layouts/body/Body";
import { COLORS, style } from "./Banner.style";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const BANNER_IMAGE = require("../../../../../../../public/images/banner.jpeg");

interface BannerProps {
  readonly onPress: () => void;
}
const Banner: FC<BannerProps> = ({ onPress }) => {
  const titleText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_BANNER_TITLE });
  const descriptionText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_BANNER_DESCRIPTION });
  const buttonText = useI18nMessage({ prefix: PREFIX, id: I18nMessages.SUBMIT_PAGE_BANNER_BUTTON });

  return (
    <Body style={{ row: style.container, column: style.column }} testID="banner">
      <Pressable style={style.pressable} testID="banner-link" onPress={onPress}>
        <ImageBackground source={BANNER_IMAGE} style={style.imageContainer}>
          <LinearGradient colors={COLORS} style={style.linearGradient} />

          <View style={[StyleSheet.absoluteFill, style.content]}>
            <Text level={2} style={style.title} heading>
              {titleText}
            </Text>

            <Text level={3} style={style.description}>
              {descriptionText}
            </Text>

            <Button style={style.button} variant={BUTTON_VARIANT.PRIMARY_LIGHT} wide={false} small>
              {buttonText}
            </Button>
          </View>
        </ImageBackground>
      </Pressable>
    </Body>
  );
};

export { Banner };
