import { PortalProvider } from "@gorhom/portal";
import React, { FC, ReactNode } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { Navigate, generatePath, useParams } from "react-router";
import { Spinner } from "@lookiero/aurora";
import { I18n } from "@lookiero/i18n-react";
import { Kameleoon } from "@lookiero/sty-psp-ab-testing";
import { Language, isValidLocale, locale, DEFAULT_LANGUAGE, countryByHostname } from "@lookiero/sty-psp-locale";
import { Notifications } from "@lookiero/sty-psp-notifications";
import { MESSAGING_CONTEXT_ID } from "../../delivery/baseBootstrap";
import { BoxPreviewSubmitProvider } from "../../domain/boxPreview/react/useSubmitBoxPreview";
import { useEnvironment } from "../../projection/environment/react/useEnvironment";
import { BoxPreviewMiddleware } from "../routing/BoxPreviewMiddleware";
import { Routes } from "../routing/routes";

interface AppProps {
  readonly children: ReactNode;
  readonly I18n: I18n;
  readonly onI18nError?: (error: Error) => void;
}

const App: FC<AppProps> = ({ children, I18n, onI18nError }) => {
  const { id, language } = useParams();
  const {
    kameleoon: { siteCode },
  } = useEnvironment();

  const country = countryByHostname(window.location.hostname);
  const i18nLocale = locale({ country, language: language as Language });

  if (!isValidLocale(i18nLocale)) {
    return (
      <Navigate to={generatePath(Routes.HOME, { language: DEFAULT_LANGUAGE[country], id: id as string })} replace />
    );
  }

  return (
    <I18n loader={<Spinner />} locale={i18nLocale} onError={onI18nError}>
      <Kameleoon loader={<Spinner />} siteCode={siteCode}>
        <BoxPreviewSubmitProvider boxPreviewId={id as string}>
          <BoxPreviewMiddleware>
            <SafeAreaProvider>
              <PortalProvider>
                <Notifications contextId={MESSAGING_CONTEXT_ID} />
                {children}
              </PortalProvider>
            </SafeAreaProvider>
          </BoxPreviewMiddleware>
        </BoxPreviewSubmitProvider>
      </Kameleoon>
    </I18n>
  );
};

export { App };
